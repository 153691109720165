import {

  DownloadOutlined,

} from "@mui/icons-material";
import React, { useState, useEffect } from "react";
import Sidebar from "../../components/Sidebar";


import { gql, useQuery } from "@apollo/client";

import { styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import moment from "moment-timezone";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#22C55E",
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const columns = [
  { id: "fileName", label: "File Name", minWidth: 130, align: "left" },
  {
    id: "createdAt",
    label: "Uploaded Date",
    minWidth: 100,
    align: "center",
    format: (value) => value.toLocaleString("en-IN"),
  },
  {
    id: "version",
    label: "Version",
    minWidth: 130,
    align: "left",
    format: (value) => value.toLocaleString("en-IN"),
  },
  {
    id: "action",
    label: "Action",
    minWidth: 130,
    align: "right",
  },
];

const ALL_PLUGIN_GET = gql`
  query($pluginType: String!){
    PluginsByType(pluginType: $pluginType) {
      id
      fileName
      type
      version
      createdAt
      downloadURL
    }
  }
`;

function Plugin() {

  const [page, setPage] = React.useState(0);

  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [pluginData, setPluginData] = useState("");

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const { data, loading, error } = useQuery(ALL_PLUGIN_GET, {
    variables: {
      pluginType: ""
    }
  });

  //BUSY INFO FETCH
  useEffect(() => {
    if (data) setPluginData(data.PluginsByType);
  }, [data, loading]);

  return (
    <div className="flex w-screen ">
      <Sidebar />
      <div className="body w-64 flex-1  p-8">
        <h1 className=" p-2 text-2xl font-thin text-green-300">
          PLUGINS
        </h1>
        <div className="h-20 w-full ">
          <div className="my-4">
            <h1 className=" p-2 text-2xl font-thin text-green-300">
              BUSY PLUGINS
            </h1>
            <div className="w-full ">
              <Paper
                sx={{ width: "100%", overflow: "hidden", heigth: "200px" }}
              >
                <TableContainer sx={{ maxHeight: 600 }}>
                  <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                      <TableRow>
                        {columns.map((column) => (
                          <StyledTableCell
                            key={column.id}
                            align={column.align}
                            style={{ minWidth: column.minWidth }}
                          >
                            {column.label}
                          </StyledTableCell>
                        ))}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {pluginData && pluginData
                        .slice(
                          page * rowsPerPage,
                          page * rowsPerPage + rowsPerPage
                        )
                        .map((row, index) => {
                          return (
                            <TableRow
                              hover
                              role="checkbox"
                              tabIndex={-1}
                              key={index}
                            >
                              {columns.map((column) => {
                                const value = row[column.id];
                                return (
                                  <TableCell
                                    key={column.id}
                                    align={column.align}
                                  >
                                    {column.id === "createdAt" ? moment(Number(value)).tz("Asia/Kolkata").format("DD:MM:YY HH:MM") :
                                      column.id === "action" ? (
                                        <a href={row["downloadURL"]}>
                                          <DownloadOutlined className="text-green-400" />
                                        </a>
                                      ) : column.format &&
                                        typeof value === "number" ? (
                                        column.format(value)
                                      ) : (
                                        value
                                      )}
                                  </TableCell>
                                );
                              })}
                            </TableRow>
                          );
                        })}
                    </TableBody>
                  </Table>
                </TableContainer>
                <TablePagination
                  rowsPerPageOptions={[10, 25, 100]}
                  component="div"
                  count={pluginData.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              </Paper>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Plugin;
