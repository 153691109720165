import React from 'react'
import Paper from "@mui/material/Paper";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";

function DataTable({ data, columns }) {
    return (
        <div>
            <Paper
                sx={{
                    padding: 2,
                    width: "100%",
                    overflow: "hidden",
                }}
            >
                <div style={{ height: "500px", width: '100%' }}>
                    <DataGrid
                        sx={{ padding: 2 }}
                        rows={data}
                        columns={columns}
                        components={{
                            Toolbar: GridToolbar,
                        }}
                        checkboxSelection={true}
                    />
                </div>
            </Paper>
        </div>
    )
}

export default DataTable