import React from "react";
import Sidebar from "../../components/Sidebar";


function Terms() {
  return (
    <div>
      <div className="flex w-screen">
        <Sidebar />
        <div className="body w-full flex-1 bg-gray-200 p-8">
          <div className="flex flex-col items-center w-2/3 m-auto p-8 rounded-2xl divide-y-2 divide-dashed mt-4">
            <h1 className="text-4xl font-bold mt-24">Terms and Conditions</h1>

            <h2 className="text-2xl font-light text-gray-600">Introduction</h2>

            <p className="text-justify text-lg my-4">
              These Website Standard Terms and Conditions written on this webpage
              shall manage your use of our website, reseller.messageapi.in accessible at
              reseller.messageapi.in.
            </p>

            <p className="text-justify text-lg my-4">
              These Terms will be applied fully and affect to your use of this
              Website. By using this Website, you agreed to accept all terms and
              conditions written in here. You must not use this Website if you
              disagree with any of these Website Standard Terms and Conditions.
            </p>

            <h2 className="text-2xl font-light text-gray-600">
              Intellectual Property Rights
            </h2>

            <p className="text-justify text-lg my-4">
              Other than the content you own, under these Terms, reseller.messageapi.in
              and/or its licensors own all the intellectual property rights and
              materials contained in this Website.
            </p>

            <p className="text-justify text-lg my-4">
              You are granted limited license only for purposes of viewing the
              material contained on this Website.
            </p>

            <p className="text-justify text-lg my-4">
              By sharing your contact data with us, you give us the permission to
              engage with you on multiple media platforms..
            </p>

            <p className="text-justify text-lg my-4">
              This website uses cookies which enable us to reach back to you on
              other platforms. By using this website, you give your approval on the
              same.
            </p>

            <h2 className="text-2xl font-light text-gray-600">Restrictions</h2>

            <p className="text-justify text-lg my-4">
              You are specifically restricted from all of the following:
            </p>

            <ol className="list-decimal space-y-2 p-4">
              <li>Publishing any Website material in any other media;</li>
              <li>
                Selling, sublicensing and/or otherwise commercializing any Website
                material;
              </li>
              <li>Publicly performing and/or showing any Website material;</li>
              <li>
                Using this Website in any way that is or may be damaging to this
                Website;
              </li>
              <li>
                Using this Website in any way that impacts user access to this
                Website;
              </li>
              <li>
                Using this Website contrary to applicable laws and regulations, or
                in any way may cause harm to the Website, or to any person or
                business entity;
              </li>
              <li>
                Engaging in any data mining, data harvesting, data extracting or any
                other similar activity in relation to this Website;
              </li>
              <li>Using this Website to engage in any advertising or marketing.</li>
            </ol>

            <p className="text-justify text-lg my-4">
              Certain areas of this Website are restricted from being access by you
              and reseller.messageapi.in may further restrict access by you to any areas
              of this Website, at any time, in absolute discretion. Any user ID and
              password you may have for this Website are confidential and you must
              maintain confidentiality as well.
            </p>

            <h2 className="text-2xl font-light text-gray-600">Your Content</h2>

            <p className="text-justify text-lg my-4">
              In these Website Standard Terms and Conditions, "Your Content" shall
              mean any audio, video text, images or other material you choose to
              display on this Website. By displaying Your Content, you grant
              reseller.messageapi.in a non-exclusive, worldwide irrevocable, sub
              licensable license to use, reproduce, adapt, publish, translate and
              distribute it in any and all media.
            </p>

            <p className="text-justify text-lg my-4">
              Your Content must be your own and must not be invading any
              third-party’s rights. reseller.messageapi.in reserves the right to remove
              any of Your Content from this Website at any time without notice.
            </p>

            <h2 className="text-2xl font-light text-gray-600">No warranties</h2>

            <p className="text-justify text-lg my-4">
              This Website is provided "as is," with all faults, and
              reseller.messageapi.in express no representations or warranties, of any
              kind related to this Website or the materials contained on this
              Website. Also, nothing contained on this Website shall be interpreted
              as advising you.
            </p>

            <h2 className="text-2xl font-light text-gray-600">
              Limitation of liability
            </h2>

            <p className="text-justify text-lg my-4">
              In no event shall reseller.messageapi.in, nor any of its officers,
              directors and employees, shall be held liable for anything arising out
              of or in any way connected with your use of this Website whether such
              liability is under contract. reseller.messageapi.in, including its
              officers, directors and employees shall not be held liable for any
              indirect, consequential or special liability arising out of or in any
              way related to your use of this Website.
            </p>

            <h2 className="text-2xl font-light text-gray-600">Indemnification</h2>

            <p className="text-justify text-lg my-4">
              You hereby indemnify to the fullest extent reseller.messageapi.in from and
              against any and/or all liabilities, costs, demands, causes of action,
              damages and expenses arising in any way related to your breach of any
              of the provisions of these Terms.
            </p>

            <h2 className="text-2xl font-light text-gray-600">Severability</h2>

            <p className="text-justify text-lg my-4">
              If any provision of these Terms is found to be invalid under any
              applicable law, such provisions shall be deleted without affecting the
              remaining provisions herein.
            </p>

            <h2 className="text-2xl font-light text-gray-600">
              Variation of Terms
            </h2>

            <p className="text-justify text-lg my-4">
              reseller.messageapi.in is permitted to revise these Terms at any time as it
              sees fit, and by using this Website you are expected to review these
              Terms on a regular basis.
            </p>

            <h2 className="text-2xl font-light text-gray-600">Assignment</h2>

            <p className="text-justify text-lg my-4">
              The reseller.messageapi.in is allowed to assign, transfer, and subcontract
              its rights and/or obligations under these Terms without any
              notification. However, you are not allowed to assign, transfer, or
              subcontract any of your rights and/or obligations under these Terms.
            </p>

            <h2 className="text-2xl font-light text-gray-600">Entire Agreement</h2>

            <p className="text-justify text-lg my-4">
              These Terms constitute the entire agreement between reseller.messageapi.in
              and you in relation to your use of this Website, and supersede all
              prior agreements and understandings.
            </p>

            <h2 className="text-2xl font-light text-gray-600">
              Governing Law &amp; Jurisdiction
            </h2>

            <p className="text-justify text-lg my-4">
              These Terms will be governed by and interpreted in accordance with the
              laws of New Delhi, and you submit to the non-exclusive jurisdiction of
              the state and federal courts located in in for the resolution of any
              disputes.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Terms;
