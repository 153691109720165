import Home from "./pages/home/Home";
import Login from "./pages/login/Login";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import { useContext } from "react";
import { AuthContext } from "./context/AuthContext";
import Qrcode from "./pages/qrcode/Qrcode";
import Api from "./pages/api/Api";
import Delivery from "./pages/report/Delivery";
import SingleMessage from "./pages/message/SingleMessage";
import MultiMessage from "./pages/message/MultiMessage";
import Users from "./pages/users/Users";
import Plans from "./pages/plans/Plans";
import Plugin from "./pages/plugins/Plugin";
import UserProfile from "./pages/userprofile/UserProfile";
import Profile from "./pages/profile/Profile";
import Signup from "./pages/signup/Signup";
import ResetPassword from "./pages/resetpassword/ResetPassword";
import PasswordChange from "./pages/resetpassword/PasswordChange";
import Order from "./pages/order/Order";
import Terms from "./pages/privacy/Terms";
import ReturnPolicy from "./pages/returnpolicy/ReturnPolicy";
import Policy from "./pages/privacy/Policy";

function App() {
  const { currentUser } = useContext(AuthContext);

  const RequireAuth = ({ children }) => {
    return currentUser ? children : <Navigate to="/login" />;
  };

  return (
    <div>
      <BrowserRouter>
        <Routes>
          <Route path="/">
            <Route path="login" element={<Login />} />
            <Route path="signup" element={<Signup />} />
            <Route path="resetpassword" element={<ResetPassword />} />
            <Route
              path="dashboard"
              element={
                <RequireAuth>
                  <Home />
                </RequireAuth>
              }
            />
            <Route
              index
              element={
                <RequireAuth>
                  <Home />
                </RequireAuth>
              }
            />
            <Route
              path="qrcode"
              element={
                <RequireAuth>
                  <Qrcode />
                </RequireAuth>
              }
            />
            <Route
              path="singlemessage"
              element={
                <RequireAuth>
                  <SingleMessage />
                </RequireAuth>
              }
            />
            <Route
              path="multimessage"
              element={
                <RequireAuth>
                  <MultiMessage />
                </RequireAuth>
              }
            />
            <Route
              path="api"
              element={
                <RequireAuth>
                  <Api />
                </RequireAuth>
              }
            />
            <Route
              path="plans"
              element={
                <RequireAuth>
                  <Plans />
                </RequireAuth>
              }
            />
            <Route path="report">
              <Route
                path="delivery"
                element={
                  <RequireAuth>
                    <Delivery />
                  </RequireAuth>
                }
              />
            </Route>
            <Route path="users">
              <Route
                index
                element={
                  <RequireAuth>
                    <Users />
                  </RequireAuth>
                }
              />
            </Route>
            <Route
              path="plugin"
              element={
                <RequireAuth>
                  <Plugin />
                </RequireAuth>
              }
            />
            <Route path="profile">
              <Route
                index
                element={
                  <RequireAuth>
                    <Profile />
                  </RequireAuth>
                }
              />
            </Route>
            <Route path="userprofile">
              <Route
                index
                element={
                  <RequireAuth>
                    <UserProfile />
                  </RequireAuth>
                }
              />
            </Route>
          </Route>
          <Route
            path="order"
            element={
              <RequireAuth>
                <Order />
              </RequireAuth>
            }
          />
          <Route path="changepassword" element={<PasswordChange />} />
          <Route path="returnpolicy" element={<ReturnPolicy />} />
          <Route path="terms" element={<Terms />} />
          <Route path="privacy" element={<Policy />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
