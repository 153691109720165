/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef } from "react";
import { styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Sidebar from "../../components/Sidebar";
import { Visibility } from "@mui/icons-material";
import { Link } from "react-router-dom";
import { useLocation, useNavigate } from "react-router-dom";
import PersonAddDisabledIcon from "@mui/icons-material/PersonAddDisabled";
import PersonIcon from "@mui/icons-material/Person";
import { v4 as uuidv4 } from "uuid";
import { Add, Edit } from "@mui/icons-material";

import { gql, useMutation, useQuery } from "@apollo/client";
import moment from "moment";
import axios from "axios";

const RESELLER_USER_GET = gql`
  query getResellerUser($resellerId: String!) {
    resellerUsers(resellerId: $resellerId) {
      id
      uid
      resellerId
      name
      email
      companyName
      organisationType
      userType
      doj
      mobile
      wallet
      totalMessages
      totalMessagesSent
      isConnected
      isActive
      expireInDays
      planType
      devices {
        id
        deviceId
        deviceName
        isConnected
        updatedAt
      }
      orders {
        orderId
        name
        amount
        status
        createdAt
      }
    }
  }
`;

const USER_GET = gql`
  query getUser($uid: String!) {
    user(uid: $uid) {
      uid
      resellerId
      name
      userType
      doj
      mobile
      wallet
      totalMessages
      totalMessagesSent
      isConnected
      isActive
      expireInDays
      planType
      devices {
        id
        deviceId
        deviceName
        isConnected
        updatedAt
      }
      orders {
        orderId
        name
        amount
        status
        createdAt
      }
    }
  }
`;

const USER_ADD = gql`
  mutation ($user: UserInput!) {
    userCreate(user: $user) {
      userErrors {
        message
      }
      user {
        id
      }
    }
  }
`;

const USER_UPDATE = gql`
  mutation ($userId: ID!, $user: UserInput!) {
    userUpdate(userId: $userId, user: $user) {
      userErrors {
        message
      }
      user {
        id
      }
    }
  }
`;

const PLAN_GET = gql`
  query ($planFor: String!) {
    plans(planFor: $planFor) {
      id
      name
      planFor
      amount
      credits
      wallet
      validity
    }
  }
`;

const USER_LOGS_ADD = gql`
  mutation ($user: UserInput!) {
    resellerLogsCreate(user: $user) {
      userErrors {
        message
      }
      user {
        id
      }
    }
  }
`;

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#22C55E",
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const columns = [
  { id: "name", label: "Name", minWidth: 170, align: "left" },
  { id: "companyName", label: "Company", minWidth: 100, align: "left" },
  { id: "organisationType", label: "Type", minWidth: 100, align: "left" },
  { id: "email", label: "Email", minWidth: 100, align: "left" },
  {
    id: "doj",
    label: "D.O.J",
    minWidth: 120,
    align: "left",
    format: (value) => value.toLocaleString("en-IN"),
  },
  {
    id: "planType",
    label: "Plan Type",
    minWidth: 120,
    align: "center",
  },
  {
    id: "wallet",
    label: "Wallet",
    minWidth: 150,
    align: "center",
  },
  {
    id: "expireInDays",
    label: "Expiry Date",
    minWidth: 150,
    align: "center",
  },
  {
    id: "isConnected",
    label: "Status",
    minWidth: 150,
    align: "center",
  },
  {
    id: "action",
    label: "Actions",
    minWidth: 170,
    align: "right",
  },
];

export default function Users() {
  const [page, setPage] = useState(0);
  const [data, setData] = useState([]);
  const [resellerInfo, setResellerInfo] = useState(null);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [editing, setEditing] = useState(false);
  const [adding, setAdding] = useState(false);
  const [formData, setFormData] = useState({
    expireInDays: 3,
    totalMessages: 30,
    planType: "DEMO",
    userType: "User",
  });

  const [oldFormData, setOldFormData] = useState("");

  const [addFormData, setAddFormData] = useState({
    expireInDays: 3,
    totalMessages: 30,
    planType: "DEMO",
    userType: "User",
  });

  const [message, setMessage] = useState(null);
  const [plans, setPlans] = useState(null);
  const [planChangeAttempt, setPlanChangeAttempt] = useState(false);

  const resellerUserRef = useRef(null);
  let [usersExpired, setUsersExpired] = useState(null);
  let [usersCreditExpired, setUsersCreditExpired] = useState(null);
  let [usersDisconnected, setUsersDisconnected] = useState(null);
  let [usersConnected, setUsersConnected] = useState(null);

  const queryTextRef = useRef();
  const queryFetchDone = useRef(false);

  const [filterGroup, setFilterGroup] = useState(null);

  const navigate = useNavigate();

  const [error, setError] = useState(false);
  const [isPending, setPending] = useState(false);

  const location = useLocation();

  let { uid: resellerUserId, editing: resellerUserEditing } =
    location.state || {};

  //GRAPHQL START
  let { uid } = JSON.parse(localStorage.getItem("user"));

  const { data: resellerData, loading: resellerLoading } = useQuery(USER_GET, {
    variables: { uid: uid },
  });

  const { data: planData, loading: planDataLoading } = useQuery(PLAN_GET, {
    variables: {
      planFor: "User",
    },
  });

  const [resellerLogsCreate] = useMutation(USER_LOGS_ADD);

  const [userCreate] = useMutation(USER_ADD, {
    refetchQueries: [
      RESELLER_USER_GET,
      {
        variables: { resellerId: uid },
      },
    ],
  });

  const [userUpdate] = useMutation(USER_UPDATE, {
    refetchQueries: [
      RESELLER_USER_GET,
      {
        variables: { resellerId: uid },
      },
    ],
  });

  let { data: resellerUserData, loading: resellerUserLoading } = useQuery(
    RESELLER_USER_GET,
    {
      variables: { resellerId: uid },
    }
  );

  //GRAPHQL END

  const filterText = (str = "") => {
    if (str) {
      let txt = str
        .replaceAll(/Firebase:|Error|Auth/gi, null)
        .replace(/[^a-zA-Z ]/gi, " ")
        .trim();
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    }

    return;
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    let val = event.target.value ? +event.target.value : 0;
    setRowsPerPage(val);
    setPage(0);
  };

  const handleEdit = (e) => {
    setEditing(!editing);

    let editData = {
      ...e,
      doj: moment(new Date(Number(e.doj)))
        .utc()
        .format("YYYY-MM-DD"),
    };
    setOldFormData(editData);
    setFormData(editData);
  };

  const disconnectUser = (e) => {
    setPending(true);
    var config = {
      method: "get",
      url: `https://messageapi.in/client/removeClient/${e.uid}`,
      headers: {
        "Content-Type": "application/json",
      },
    };

    axios(config)
      .then(function (response) {
        console.log(response.data);
        setPending(false);
        //UPDATE USER IS ACTIVE STATUS
        userUpdate({
          variables: {
            userId: e.uid,
            user: {
              isActive: !e.isActive,
            },
          },
          refetchQueries: [
            RESELLER_USER_GET,
            {
              variables: { resellerId: uid },
            },
          ],
        });
      })
      .catch(function (error) {
        console.log(error);
        setPending(false);
      });
  };

  const handleDisable = async (e) => {
    userUpdate({
      variables: {
        userId: e.uid,
        user: {
          isActive: !e.isActive,
        },
      },
      refetchQueries: [
        RESELLER_USER_GET,
        {
          variables: { resellerId: uid },
        },
      ],
    });
  };

  const ActionButton = (props) => {
    return (
      <>
        <div className="flex items-center justify-around">
          <Link to="/userprofile" state={{ ...props }}>
            <Visibility />
          </Link>

          <button align={props.align} onClick={() => handleEdit(props.value)}>
            <Edit />
          </button>
          <button
            align={props.align}
            onClick={() => handleDisable(props.value)}
            className={
              props.value && props.value.isActive
                ? "text-green-600"
                : "text-red-600"
            }
          >
            {props.value && props.value.isActive ? (
              <PersonIcon />
            ) : (
              <PersonAddDisabledIcon />
            )}
          </button>
        </div>
      </>
    );
  };

  const handleChange = (event) => {
    setFormData((prevData) => {
      return { ...prevData, [event.target.name]: event.target.value };
    });
  };

  const handleAddChange = (event) => {
    setAddFormData((prevData) => {
      return { ...prevData, [event.target.name]: event.target.value };
    });
  };

  const handleShowCreate = async (event) => {
    event.preventDefault();
    setAdding(!adding);
  };

  const handleCreate = async (event) => {
    event.preventDefault();

    setPending(true);

    let userAddData = getFormData("addForm");

    userCreate({
      variables: {
        user: {
          ...userAddData,
          uid: uuidv4().replace(/[^a-zA-Z0-9]/g, ""),
          resellerId: resellerInfo.uid,
          doj: new Date(),
          expireInDays: 3,
          planType: "DEMO",
          totalMessages: 30,
        },
      },
      onError: (error) => {

        // Check if the error is a Prisma P2002 error
        const prismaError = error?.message || ""; // Extract error message
        if (
          prismaError.includes("Unique constraint failed on the fields: (`email`)")
        ) {
          setError("Email is already registered");
        } else if (
          prismaError.includes("Unique constraint failed on the fields: (`mobile`)")
        ) {
          setError("Mobile is already registered");
        } else {
          setError(error.message || "An unexpected error occurred");
        }

        setPending(false);
      },
      onCompleted: (data) => {
        setPending(false);
        setAdding(!adding);
        window.location.reload();
      },
    });

  };

  const handlePlanChange = async (event) => {
    event.preventDefault();
    let selectedPlan = event.target.value;

    //SEARCH PLAN DETAILS
    let planDetails = plans.find((pf) => pf.name === selectedPlan);

    //CALCULATE CURRENT DATE
    let y = new Date().getFullYear();
    let m = new Date().getMonth() + 1;
    let d = new Date().getDate();

    m = m.toString().length === 1 ? "0" + m : m;
    d = d.toString().length === 1 ? "0" + d : d;

    setPlanChangeAttempt(true);

    setFormData((prevData) => {
      return {
        ...prevData,
        ["totalMessages"]: planDetails.credits,
        ["wallet"]: planDetails.wallet,
        ["doj"]: y + "-" + m + "-" + d,
        ["expireInDays"]: planDetails.validity,
        ["planType"]: planDetails.planType,
      };
    });
  };

  const getFormData = (formName) => {
    var data = new FormData(document.getElementById(formName));
    data = data.entries();
    var obj = data.next();
    var retrieved = {};
    while (undefined !== obj.value) {
      retrieved[obj.value[0]] = obj.value[1];
      obj = data.next();
    }

    return retrieved;
  };

  const handleOldLogCreate = async (editedUserInfo) => {
    delete oldFormData.id;
    delete oldFormData.__typename;
    oldFormData["doj"] = new Date(oldFormData.doj);

    await resellerLogsCreate({
      variables: {
        user: {
          ...oldFormData,
        },
      },
      onCompleted: () => {
        handleNewLogCreate(editedUserInfo);
      },
      onError: (error) => {
        setPending(false);
        setError(filterText(error.response.data.message));
      },
    });
  };

  const handleNewLogCreate = async (editedUserInfo) => {
    delete editedUserInfo.id;

    await resellerLogsCreate({
      variables: {
        user: {
          ...editedUserInfo,
        },
      },
      onCompleted: () => {
        //RESET ALL
        setTimeout(() => {
          setEditing(false);
          setMessage(null);
          setPending(false);
          resellerUserEditing = false;
          navigate("/userprofile", {
            state: {
              ...editedUserInfo,
            },
          });
        }, 1000);
      },
      onError: (error) => {
        setPending(false);
        setError(filterText(error.response.data.message));
      },
    });
  };

  const calculateCreditsDeduction = (
    resellerTotalMessage,
    userCredits,
    userPlan
  ) => {
    if (userPlan === "MAP 3A") {
      switch (resellerInfo.planType) {
        case "MAP 8A":
          return resellerTotalMessage - 3750;
        case "MAP 8B":
          return resellerTotalMessage - 4200;
        case "MAP 8C":
          return resellerTotalMessage - 4600;
        case "MAP 8D":
          return resellerTotalMessage - 5050;
        default:
          return resellerTotalMessage - userCredits;
          break;
      }
    } else if (userPlan === "MAP 3B") {
      switch (resellerInfo.planType) {
        case "MAP 8A":
          return resellerTotalMessage - 7500;
        case "MAP 8B":
          return resellerTotalMessage - 8400;
        case "MAP 8C":
          return resellerTotalMessage - 9200;
        case "MAP 8D":
          return resellerTotalMessage - 10120;
        default:
          return resellerTotalMessage - userCredits;
          break;
      }
    } else if (userPlan === "MAP 3C") {
      switch (resellerInfo.planType) {
        case "MAP 8A":
          return resellerTotalMessage - 11000;
        case "MAP 8B":
          return resellerTotalMessage - 12500;
        case "MAP 8C":
          return resellerTotalMessage - 13600;
        case "MAP 8D":
          return resellerTotalMessage - 15000;
        default:
          return resellerTotalMessage - userCredits;
          break;
      }
    } else {
      return resellerTotalMessage - userCredits;
    }
  };

  const handleUpdate = async (event) => {
    event.preventDefault();

    setPending(true);

    let userUpdateData = getFormData("editFormData");

    if (userUpdateData.planType === "DEMO" && planChangeAttempt) {
      setMessage("You are not allowed to assign Demo Plan :(");
    } else {
      if (planChangeAttempt) {

        //COMPARE PLAN WITH SELECTED PLAN AND VALIDATE TRANSFER

        let planDetails = plans.find((pf) => {
          return pf.name.trim() === userUpdateData.planType.trim();
        });

        let creditReply = false;
        let walletReply = false;

        if (planDetails.credits > 0) {
          creditReply = window.confirm(
            `Do you really want to transfer ${planDetails.credits} to your user`
          );
        } else {
          walletReply = window.confirm(
            `Do you really want to wallet transfer (${planDetails.wallet}) to your user`
          );
        }

        if (creditReply) {

          let resellerRemainingMessage =
            resellerInfo.totalMessages - resellerInfo.totalMessagesSent;

          if (resellerRemainingMessage - planDetails.credits >= 0) {
            // DEDUCT NO OF MSG FROM RESELLER AND UPDATE RESELLER CREDITS

            await userUpdate({
              variables: {
                userId: resellerInfo.uid,
                user: {
                  totalMessages: calculateCreditsDeduction(
                    resellerInfo.totalMessages,
                    planDetails.credits,
                    planDetails.name
                  ),
                  // totalMessages: resellerInfo.totalMessages - planDetails.credits,
                },
              },
            });

            //UPDATE NO OF MSG TO CLIENT
            let userRemainingMessage =
              oldFormData.totalMessages - oldFormData.totalMessagesSent;

            let editedUserInfo = {
              ...userUpdateData,
              wallet: 0,
              totalMessages: 0 + planDetails.credits,
              totalMessagesSent: 0,
              planType: planDetails.name,
              expireInDays: planDetails.validity,
              doj: new Date(),
            };

            await userUpdate({
              variables: {
                userId: editedUserInfo.uid,
                user: {
                  ...editedUserInfo,
                },
              },
            });

            await handleOldLogCreate({
              ...editedUserInfo,
            });
          } else {
            setError("You have less credits to transfer");
          }
        } else if (walletReply) {

          let resellerRemainingWallet =
            resellerInfo.wallet - planDetails.wallet;

          if (resellerRemainingWallet >= 0) {
            // DEDUCT NO OF WALLET

            await userUpdate({
              variables: {
                userId: resellerInfo.uid,
                user: {
                  wallet: Number(resellerRemainingWallet),
                },
              },
            });

            //UPDATE NO OF MSG TO CLIENT
            let userRemainingWallet = planDetails.wallet;

            let editedUserInfo = {
              ...userUpdateData,
              totalMessages: 0,
              totalMessagesSent: 0,
              wallet: Number(userRemainingWallet),
              planType: planDetails.name,
              expireInDays: planDetails.validity,
              doj: new Date(),
            };

            await userUpdate({
              variables: {
                userId: editedUserInfo.uid,
                user: {
                  ...editedUserInfo,
                },
              },
            });

            await handleOldLogCreate({
              ...editedUserInfo,
            });
          } else {
            setError("You have less wallet credits to transfer");
          }
        } else {
          setError("You have less credits to transfer");
        }
      } else {
        let reply = window.confirm(
          `Do you really want to change profile of the user`
        );

        if (reply) {
          //UPDATE DETAILS
          try {
            if (userUpdateData) {
              delete userUpdateData.doj;
              let editedUserInfo = {
                ...userUpdateData,
                totalMessagesSent: Number(userUpdateData.totalMessagesSent),
                totalMessages: Number(userUpdateData.totalMessages),
                expireInDays: Number(userUpdateData.expireInDays),
              };

              await userUpdate({
                variables: {
                  userId: editedUserInfo.uid,
                  user: {
                    ...editedUserInfo,
                  },
                },
              });

              await handleOldLogCreate({
                ...editedUserInfo,
              });
            }

            setTimeout(() => {
              setEditing(!editing);
              setMessage(null);
              setPending(false);
              navigate("/userprofile", { state: { ...userUpdateData } });
              resellerUserEditing = false;
            }, 1000);
          } catch (err) {
            console.log(err);
          }
        }
      }
    }

    setPending(false);
  };

  const searchHandler = async (e) => {
    e.preventDefault();
    queryTextRef.current = e.target.value;
  };

  const searchFire = async () => {
    let mobileFilter = data.filter(
      (d) => d.mobile && d.mobile.toLowerCase().includes(queryTextRef.current)
    );

    let emailFilter = data.filter(
      (d) => d.email && d.email.toLowerCase().includes(queryTextRef.current)
    );

    let nameFilter = data.filter(
      (d) => d.name && d.name.toLowerCase().includes(queryTextRef.current)
    );

    if (mobileFilter.length > 0) {
      setData(mobileFilter);
    } else if (emailFilter.length > 0) {
      setData(emailFilter);
    } else if (nameFilter.length > 0) {
      setData(nameFilter);
    }
  };

  const validData = (data) => {
    return data.toString().length == 1 ? "0" + data : data;
  };

  const handleFilterUser = (data, gs) => {
    setData(data);
    setFilterGroup(gs);
    filterFunction();
  };

  const filterFunction = () => {
    if (resellerUserRef.current && resellerUserRef.current.length > 0) {
      //CALCULATE CONNECTED
      usersConnected = resellerUserRef.current.filter((df) => df.isConnected);
      setUsersConnected(usersConnected);
      //CALCULATE DISCONNECTED
      usersDisconnected = resellerUserRef.current.filter(
        (df) => !df.isConnected
      );
      setUsersDisconnected(usersDisconnected);
      //CALCULATE EXPIRED
      usersExpired = resellerUserRef.current.filter((df) => {
        let expiryDate = "";
        expiryDate = moment(Number(df.doj))
          .add(df.expireInDays, "days")
          .utc()
          .format("YYYY-MM-DD");
        let now = moment(new Date()).utc().format("YYYY-MM-DD");
        return now > expiryDate;
      });
      setUsersExpired(usersExpired);
      //CALCULATE CREDIT FINISHED
      usersCreditExpired = resellerUserRef.current.filter(
        (df) => df.totalMessages <= 0
      );
      setUsersCreditExpired(usersCreditExpired);
    }
  };

  const resetUser = () => {
    setEditing(false);
    setAdding(false);
  };

  useEffect(() => {
    if (message || error) {
      setTimeout(() => {
        setMessage("");
        setError("");
      }, 10000);
    }
  }, [message, error]);

  useEffect(() => {
    if (resellerData) {
      let uinfo = resellerData.user;

      setResellerInfo(prev => {
        return {
          ...uinfo,
          isConnected: uinfo.devices.isConnected
        }
      });
    }
  }, [resellerData, resellerLoading]);

  useEffect(() => {
    if (planData) setPlans(planData.plans);
  }, [planData, planDataLoading]);

  useEffect(() => {
    if (resellerUserData) {
      let resellerUsers = [...resellerUserData.resellerUsers];

      if (resellerUsers.length > 0) {
        resellerUsers = resellerUsers.sort((a, b) => {
          return b.doj - a.doj;
        });

        resellerUsers = resellerUsers.map(rs => {
          return {
            ...rs,
            isConnected: rs?.devices[0]?.isConnected
          }
        })

        setData(resellerUsers);
        resellerUserRef.current = resellerUsers;
        queryFetchDone.current = true;
        filterFunction();
      }
    }
  }, [resellerUserData, resellerUserLoading]);

  useEffect(() => {
    if (resellerUserEditing && planData) {
      setEditing(!editing);

      let e = location.state;

      let editData = {
        ...e,
        doj: moment(new Date(Number(e.doj)))
          .utc()
          .format("YYYY-MM-DD"),
      };

      setFormData(editData);
      setOldFormData(editData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [planData, resellerUserData]);

  return (
    <div className="flex w-screen">
      <Sidebar resetUser={resetUser} />
      {data && !adding && !editing && (
        <div className="body w-64 flex-1  p-8">
          <h1 className="mb-4 rounded p-2 text-center text-2xl font-semibold tracking-wider text-green-300 shadow-xl">
            {filterGroup ? filterGroup : "Users"}
          </h1>
          {data && data.length >= 0 ? (
            <Paper sx={{ width: "100%", overflow: "hidden" }}>
              <div className="flex w-full items-center justify-center">
                <input
                  type="text"
                  className="m-4 w-10/12 rounded-full border-2 border-gray-700 p-2"
                  placeholder="May I help You :)"
                  onChange={searchHandler}
                  onKeyPress={(ev) => {
                    if (ev.key === "Enter") {
                      // Do code here
                      ev.preventDefault();
                      searchFire();
                    }
                  }}
                />

                <button
                  onClick={handleShowCreate}
                  className="rounded-full bg-green-500 py-2 px-4 font-mono text-white"
                >
                  <Add /> New User
                </button>
              </div>
              <TableContainer sx={{ maxHeight: 500 }}>
                <Table stickyHeader aria-label="sticky table">
                  <TableHead>
                    <TableRow>
                      {columns?.map((column) => (
                        <StyledTableCell
                          key={column.id}
                          align={column.align}
                          style={{ minWidth: column.minWidth }}
                        >
                          {column.label}
                        </StyledTableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {data &&
                      data.length > 0 &&
                      data
                        .slice(
                          page * rowsPerPage,
                          page * rowsPerPage + rowsPerPage
                        )
                        .map((row) => {
                          return (
                            <TableRow
                              hover
                              role="checkbox"
                              tabIndex={-1}
                              key={row.uid}
                              sx={{
                                backgroundColor: row["userExpired"]
                                  ? "red"
                                  : null,
                                color: "rgba(255, 255, 255, 0)",
                              }}
                            >
                              {columns?.map((column) => {
                                const value = row[column.id];
                                return (
                                  <TableCell
                                    key={column.id}
                                    align={column.align}
                                  >
                                    {column.id === "isConnected" &&
                                      value === true ? (
                                      <span className="inline-block h-4 w-4 rounded-full bg-green-500 text-center"></span>
                                    ) : column.id === "isConnected" &&
                                      !value ? (
                                      <span className="inline-block h-4 w-4 rounded-full bg-red-500 text-center"></span>
                                    ) : column.id === "action" ? (
                                      <ActionButton value={row} />
                                    ) : column.id === "doj" ? (
                                      moment(new Date(Number(value)))
                                        .utc()
                                        .format("DD-MM-YYYY")
                                    ) : column.format &&
                                      typeof value === "number" ? (
                                      column.format(value)
                                    ) : (
                                      value
                                    )}
                                  </TableCell>
                                );
                              })}
                            </TableRow>
                          );
                        })}
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                rowsPerPageOptions={[10, 25, 100]}
                component="div"
                count={data && data.length ? data.length : 0}
                rowsPerPage={rowsPerPage ? rowsPerPage : 0}
                page={page ? page : 0}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </Paper>
          ) : (
            <p className="text-3xl font-semibold text-red-500 ">
              no records found
            </p>
          )}
          {/*  */}
          <div className="mt-4">
            <div className="xs:flex-col xs:flex-wrap flex items-center justify-around space-y-2">
              <div
                className="flex w-1/5 cursor-pointer bg-green-200 p-2"
                onClick={() => handleFilterUser(usersExpired, "Plan Expired")}
                title="Click me!"
              >
                <p className="font-md tracking-widest text-gray-600">
                  EXPIRED :{" "}
                </p>
                <p>{usersExpired?.length ? usersExpired.length : 0}</p>
              </div>
              {/*  */}
              <div
                className="flex w-1/5 cursor-pointer bg-red-200 p-2"
                onClick={() =>
                  handleFilterUser(usersCreditExpired, "Credits Expired")
                }
                title="Click me!"
              >
                <p className="font-md tracking-widest text-gray-600">
                  CREDITS EXPIRED :{" "}
                </p>
                <p>
                  {usersCreditExpired?.length ? usersCreditExpired.length : 0}
                </p>
              </div>
              {/*  */}
              <div
                className="flex w-1/5 cursor-pointer bg-blue-200 p-2"
                onClick={() =>
                  handleFilterUser(usersDisconnected, "Disconnected User")
                }
                title="Click me!"
              >
                <p className="font-md tracking-widest text-gray-600">
                  DISCONNECTED :{" "}
                </p>
                <p>
                  {usersDisconnected?.length ? usersDisconnected.length : 0}
                </p>
              </div>
              <div
                className="flex w-1/5 cursor-pointer bg-blue-200 p-2"
                onClick={() =>
                  handleFilterUser(usersConnected, "Connected User")
                }
                title="Click me!"
              >
                <p className="font-md tracking-widest text-gray-600">
                  CONNECTED :{" "}
                </p>
                <p>{usersConnected?.length ? usersConnected.length : 0}</p>
              </div>
            </div>
          </div>
        </div>
      )}

      {editing && (
        <div className="body h-screen w-64 flex-1 overflow-y-scroll bg-gray-200 p-8">
          <p className="mb-4 w-full text-center font-sans text-xl text-red-500">
            {message && message !== "" ? filterText(message) : ""}
          </p>
          <div className="m-auto w-[50%] md:grid md:grid-cols-2 md:gap-6">
            <div className="mt-5 md:col-span-2 md:mt-0">
              <form
                onSubmit={handleUpdate}
                autoComplete="off"
                id="editFormData"
              >
                <input
                  type="hidden"
                  name="password"
                  id="password"
                  readOnly
                  value={formData.password}
                />
                <input
                  type="hidden"
                  name="uid"
                  id="uid"
                  readOnly
                  value={resellerUserEditing ? resellerUserId : formData.uid}
                />
                <input
                  type="hidden"
                  name="totalMessagesSent"
                  id="totalMessagesSent"
                  readOnly
                  value={formData.totalMessagesSent}
                />

                <div className="overflow-hidden shadow sm:rounded-md">
                  <div className="bg-white px-4 py-5 sm:p-6">
                    <div className="grid grid-cols-6 gap-6">
                      <div className="col-span-6 sm:col-span-6">
                        <label
                          htmlFor="resellerId"
                          className={
                            formData?.userType === "User"
                              ? "visible block text-sm font-medium text-gray-700"
                              : "invisible block text-sm font-medium  text-gray-700"
                          }
                        >
                          Reseller User ID
                        </label>
                        <input
                          type={
                            formData?.userType === "User" ? "text" : "hidden"
                          }
                          name="resellerId"
                          id="resellerId"
                          readOnly
                          value={
                            resellerUserEditing ? uid : formData.resellerId
                          }
                          onChange={handleChange}
                          className=" mt-1 block w-full rounded-md border-gray-300 p-2 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                        />
                      </div>
                      <div className="col-span-6 sm:col-span-3">
                        <label
                          htmlFor="name"
                          className="block text-sm font-medium text-gray-700"
                        >
                          First Name
                        </label>
                        <input
                          type="text"
                          name="name"
                          id="name"
                          value={formData?.name}
                          onChange={handleChange}
                          className="mt-1 block w-full rounded-md border-gray-300 p-2 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                        />
                      </div>
                      <div className="col-span-6 sm:col-span-3">
                        <label
                          htmlFor="doj"
                          className="block text-sm font-medium text-gray-700"
                        >
                          Date of Joining
                        </label>
                        <input
                          type="date"
                          name="doj"
                          id="doj"
                          value={formData?.doj}
                          readOnly
                          className=" mt-1 block w-full rounded-md border-gray-300 p-2 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                        />
                      </div>
                      <div className="col-span-6 sm:col-span-3">
                        <label
                          htmlFor="companyName"
                          className="block text-sm font-medium text-gray-700"
                        >
                          Business Name
                        </label>
                        <input
                          type="text"
                          name="companyName"
                          id="companyName"
                          value={formData?.companyName}
                          onChange={handleChange}
                          className="mt-1 block w-full rounded-md border-gray-300 p-2 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                        />
                      </div>
                      <div className="col-span-6 sm:col-span-3">
                        <label
                          htmlFor="organisationType"
                          className="block text-sm font-medium text-gray-700"
                        >
                          Business Product/Services
                        </label>
                        <input
                          type="text"
                          name="organisationType"
                          id="organisationType"
                          value={formData?.organisationType}
                          onChange={handleChange}
                          className="mt-1 block w-full rounded-md border-gray-300 p-2 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                        />
                      </div>
                      <div className="col-span-6 sm:col-span-3">
                        <label
                          htmlFor="mobile"
                          className="block text-sm font-medium text-gray-700"
                        >
                          Mobile
                        </label>
                        <input
                          type="mobile"
                          name="mobile"
                          id="mobile"
                          value={formData?.mobile}
                          onChange={handleChange}
                          readOnly
                          className=" mt-1 block w-full rounded-md border-gray-300 p-2 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                        />
                      </div>
                      <div className="col-span-6 sm:col-span-3">
                        <label
                          htmlFor="email"
                          className="block text-sm font-medium text-gray-700"
                        >
                          Email address / Login ID
                        </label>
                        <input
                          type="text"
                          name="email"
                          id="email"
                          value={formData?.email}
                          onChange={handleChange}
                          className="mt-1 block w-full rounded-md border-gray-300 p-2 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                          readOnly
                        />
                      </div>
                      <div className="col-span-6 sm:col-span-3">
                        <label
                          htmlFor="erpType"
                          className="block text-sm font-medium text-gray-700"
                        >
                          ERP Type
                        </label>
                        <select
                          name="erpType"
                          id="erpType"
                          value={formData?.organisationType}
                          onChange={handleChange}
                          className="mt-1 block w-full rounded-md border border-gray-300 bg-white p-2 py-2 px-3 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                        >
                          <option value="">Please select</option>
                          <option value="Tally">Tally</option>
                          <option value="Busy">Busy</option>
                          <option value="Other">Other</option>
                        </select>
                      </div>
                      <div className="col-span-6 sm:col-span-3">
                        <label
                          htmlFor="bankName"
                          className="block text-sm font-medium text-gray-700"
                        >
                          Bank Name
                        </label>
                        <input
                          type="text"
                          name="bankName"
                          id="bankName"
                          value={formData?.bankName}
                          onChange={handleChange}
                          className="mt-1 block w-full rounded-md border-gray-300 p-2 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                        />
                      </div>
                      <div className="col-span-6 sm:col-span-3">
                        <label
                          htmlFor="country"
                          className="block text-sm font-medium text-gray-700"
                        >
                          Country / Region
                        </label>
                        <select
                          required
                          id="country"
                          name="country"
                          value={
                            formData?.country ? formData?.country : "India"
                          }
                          onChange={handleChange}
                          className="mt-1 block w-full rounded-md border border-gray-300 bg-white p-2 py-2 px-3 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                        >
                          <option>Please Select</option>
                          <option>India</option>
                          <option>United States</option>
                          <option>Canada</option>
                          <option>Mexico</option>
                        </select>
                      </div>
                      <div className="col-span-6 sm:col-span-3">
                        <label
                          htmlFor="userType"
                          className="block text-sm font-medium text-gray-700"
                        >
                          User Type
                        </label>
                        <select
                          required
                          id="userType"
                          name="userType"
                          value={
                            formData?.userType ? formData?.userType : "User"
                          }
                          onChange={handleChange}
                          className="mt-1 block w-full rounded-md border border-gray-300 bg-white p-2 py-2 px-3 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                        >
                          <option>Please Select</option>
                          <option>User</option>
                        </select>
                      </div>
                      <div className="col-span-6 sm:col-span-6 lg:col-span-2">
                        <label
                          htmlFor="planType"
                          className="block text-sm font-medium text-gray-700"
                        >
                          Plan Type
                        </label>
                        <select
                          required
                          id="planType"
                          name="planType"
                          value={formData?.planType}
                          onChange={handlePlanChange}
                          className="mt-1 block w-full rounded-md border-gray-300 p-2 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                        >
                          <option>Please Select</option>
                          {plans?.map((p) => {
                            return (
                              <option key={p.id} value={p.name}>
                                {p.name}
                              </option>
                            );
                          })}
                        </select>
                      </div>
                      <div className="col-span-6 sm:col-span-3 lg:col-span-2">
                        <label
                          htmlFor="expireInDays"
                          className=" block text-sm font-medium text-gray-700"
                        >
                          Expire (In Days)
                        </label>
                        <input
                          type="text"
                          name="expireInDays"
                          id="expireInDays"
                          value={formData?.expireInDays}
                          readOnly
                          className=" mt-1 block w-full rounded-md border-gray-300 p-2 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                        />
                      </div>
                      <div className="col-span-6 sm:col-span-3 lg:col-span-2">
                        <label
                          htmlFor="totalMessages"
                          className="block text-sm font-medium text-gray-700"
                        >
                          Total Messages
                        </label>
                        <input
                          type="number"
                          name="totalMessages"
                          id="totalMessages"
                          value={formData?.totalMessages}
                          readOnly
                          className=" mt-1 block w-full rounded-md border-gray-300 p-2 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                        />
                      </div>
                      <div className="col-span-6 sm:col-span-3 lg:col-span-2">
                        <label
                          htmlFor="wallet"
                          className="block text-sm font-medium text-gray-700"
                        >
                          Wallet
                        </label>
                        <input
                          type="number"
                          name="wallet"
                          id="wallet"
                          value={formData?.wallet}
                          readOnly
                          className=" mt-1 block w-full rounded-md border-gray-300 p-2 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                        />
                      </div>
                    </div>
                  </div>
                  <div>
                    <p className="font-md my-4 text-red-400">{error}</p>
                  </div>
                  <div className="bg-gray-50 px-4 py-3 text-right sm:px-6">
                    {!isPending && (
                      <button
                        type="submit"
                        value="Submit"
                        className="inline-flex justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                      >
                        Save
                      </button>
                    )}
                    {isPending && (
                      <button
                        disabled
                        className="inline-flex justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                      >
                        ...Processing
                      </button>
                    )}
                    <button
                      type="submit"
                      value="Submit"
                      onClick={() => setEditing(false)}
                      className="ml-4 inline-flex justify-center rounded-md border border-transparent bg-yellow-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      )}

      {adding && (
        <div className="body m-auto h-screen flex-1 overflow-y-scroll bg-gray-200 p-2">
          <div className="w-[80%] overflow-y-scroll md:grid md:grid-cols-3 md:gap-6">
            <p className="mb-4 w-full text-center font-sans text-xl text-red-500">
              {message && message !== "" ? filterText(message) : ""}
            </p>

            <div className="mt-5 md:col-span-2 md:mt-0">
              <form onSubmit={handleCreate} autoComplete="off" id="addForm">
                <div className="overflow-hidden shadow sm:rounded-md">
                  <div className="bg-white px-4 py-5 sm:p-6">
                    <div className="grid grid-cols-6 gap-6">
                      <div className="col-span-6 sm:col-span-3">
                        <label
                          htmlFor="resellerId"
                          className="block text-sm font-medium text-gray-700"
                        >
                          Reseller User ID
                        </label>
                        <input
                          type="text"
                          name="resellerId"
                          id="resellerId"
                          readOnly
                          value={resellerInfo.uid}
                          className=" mt-1 block w-full rounded-md border-gray-300 p-2 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                        />
                      </div>
                      <div className="col-span-6 sm:col-span-3">
                        <label
                          htmlFor="resellerId"
                          className="block text-sm font-medium text-gray-700"
                        >
                          My Remaining Credits
                        </label>
                        <input
                          type="number"
                          readOnly
                          value={
                            resellerInfo.totalMessages -
                            resellerInfo.totalMessagesSent
                          }
                          className=" mt-1 block w-full rounded-md border-gray-300 p-2 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                        />
                      </div>
                      <div className="col-span-6 sm:col-span-3">
                        <label
                          htmlFor="name"
                          className="block text-sm font-medium text-gray-700"
                        >
                          First Name
                        </label>
                        <input
                          type="text"
                          name="name"
                          id="name"
                          value={addFormData?.name}
                          onChange={handleAddChange}
                          className="mt-1 block w-full rounded-md border-gray-300 p-2 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                        />
                      </div>
                      <div className="col-span-6 sm:col-span-3">
                        <label
                          htmlFor="doj"
                          className="block text-sm font-medium text-gray-700"
                        >
                          Date of Joining
                        </label>
                        <input
                          type="date"
                          name="doj"
                          id="doj"
                          value={
                            addFormData?.doj
                              ? addFormData?.doj
                              : new Date().getFullYear() +
                              "-" +
                              validData(new Date().getMonth() + 1) +
                              "-" +
                              validData(new Date().getDate())
                          }
                          readOnly
                          className=" mt-1 block w-full rounded-md border-gray-300 p-2 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                        />
                      </div>
                      <div className="col-span-6 sm:col-span-3">
                        <label
                          htmlFor="companyName"
                          className="block text-sm font-medium text-gray-700"
                        >
                          Business Name
                        </label>
                        <input
                          type="text"
                          name="companyName"
                          id="companyName"
                          value={addFormData?.companyName}
                          onChange={handleAddChange}
                          className="mt-1 block w-full rounded-md border-gray-300 p-2 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                        />
                      </div>
                      <div className="col-span-6 sm:col-span-3">
                        <label
                          htmlFor="organisationType"
                          className="block text-sm font-medium text-gray-700"
                        >
                          Business Product/Services
                        </label>
                        <input
                          type="text"
                          name="organisationType"
                          id="organisationType"
                          value={addFormData?.organisationType}
                          onChange={handleAddChange}
                          className="mt-1 block w-full rounded-md border-gray-300 p-2 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                        />
                      </div>
                      <div className="col-span-6 sm:col-span-3">
                        <label
                          htmlFor="mobile"
                          className="block text-sm font-medium text-gray-700"
                        >
                          Mobile
                        </label>
                        <input
                          type="number"
                          name="mobile"
                          id="mobile"
                          value={addFormData?.mobile}
                          onChange={handleAddChange}
                          className=" mt-1 block w-full rounded-md border-gray-300 p-2 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                        />
                      </div>
                      <div className="col-span-6 sm:col-span-3">
                        <label
                          htmlFor="password"
                          className="block text-sm font-medium text-gray-700"
                        >
                          Password
                        </label>
                        <input
                          type="text"
                          name="password"
                          id="password"
                          value={addFormData?.password}
                          onChange={handleAddChange}
                          className=" mt-1 block w-full rounded-md border-gray-300 p-2 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                        />
                      </div>
                      <div className="col-span-6">
                        <label
                          htmlFor="email"
                          className="block text-sm font-medium text-gray-700"
                        >
                          Email address / Login ID
                        </label>
                        <input
                          type="text"
                          name="email"
                          id="email"
                          value={addFormData?.email}
                          onChange={handleAddChange}
                          className="mt-1 block w-full rounded-md border-gray-300 p-2 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                        />
                      </div>
                      <div className="col-span-6 sm:col-span-3">
                        <label
                          htmlFor="erpType"
                          className="block text-sm font-medium text-gray-700"
                        >
                          ERP Type
                        </label>
                        <select
                          name="erpType"
                          id="erpType"
                          value={addFormData?.erpType}
                          onChange={handleAddChange}
                          className="mt-1 block w-full rounded-md border border-gray-300 bg-white p-2 py-2 px-3 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                        >
                          <option value="">Please select</option>
                          <option value="Tally">Tally</option>
                          <option value="Busy">Busy</option>
                          <option value="Other">Other</option>
                        </select>
                      </div>
                      <div className="col-span-6 sm:col-span-3">
                        <label
                          htmlFor="bankName"
                          className="block text-sm font-medium text-gray-700"
                        >
                          Bank Name
                        </label>
                        <input
                          type="text"
                          name="bankName"
                          id="bankName"
                          value={addFormData?.bankName}
                          onChange={handleAddChange}
                          className="mt-1 block w-full rounded-md border-gray-300 p-2 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                        />
                      </div>
                      <div className="col-span-6 sm:col-span-3 ">
                        <label
                          htmlFor="country"
                          className="block text-sm font-medium text-gray-700"
                        >
                          Country / Region
                        </label>
                        <select
                          id="country"
                          name="country"
                          value={
                            addFormData?.country
                              ? addFormData?.country
                              : "India"
                          }
                          onChange={handleAddChange}
                          className="mt-1 block w-full rounded-md border border-gray-300 bg-white p-2 py-2 px-3 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                        >
                          <option>Please Select</option>
                          <option>India</option>
                          <option>United States</option>
                          <option>Canada</option>
                          <option>Mexico</option>
                        </select>
                      </div>
                      <div className="col-span-6 sm:col-span-3 ">
                        <label
                          htmlFor="userType"
                          className="block text-sm font-medium text-gray-700"
                        >
                          User Type
                        </label>
                        <select
                          id="userType"
                          name="userType"
                          value={
                            addFormData?.userType
                              ? addFormData?.userType
                              : "User"
                          }
                          onChange={handleAddChange}
                          className="mt-1 block w-full rounded-md border border-gray-300 bg-white p-2 py-2 px-3 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                        >
                          <option>Please Select</option>
                          <option>User</option>
                        </select>
                      </div>
                      <div className="col-span-6 sm:col-span-6 lg:col-span-2">
                        <label
                          htmlFor="planType"
                          className="block text-sm font-medium text-gray-700"
                        >
                          Plan Type
                        </label>
                        <select
                          required
                          name="planType"
                          id="planType"
                          value={addFormData?.planType}
                          onChange={handlePlanChange}
                          disabled
                          className="mt-1 block w-full rounded-md border-gray-300 p-2 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                        >
                          {plans?.map((p) => {
                            return <option key={p.id}>{p.name}</option>;
                          })}
                        </select>
                      </div>
                      <div className="col-span-6 sm:col-span-3 lg:col-span-2">
                        <label
                          htmlFor="expireInDays"
                          className=" block text-sm font-medium text-gray-700"
                        >
                          Expire (In Days)
                        </label>
                        <input
                          type="number"
                          name="expireInDays"
                          id="expireInDays"
                          readOnly
                          value={Number(addFormData?.expireInDays)}
                          className=" mt-1 block w-full rounded-md border-gray-300 p-2 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                        />
                      </div>
                      <div className="col-span-6 sm:col-span-3 lg:col-span-2">
                        <label
                          htmlFor="totalMessages"
                          className="block text-sm font-medium text-gray-700"
                        >
                          Total Messages
                        </label>
                        <input
                          type="number"
                          name="totalMessages"
                          id="totalMessages"
                          value={Number(addFormData?.totalMessages)}
                          readOnly
                          className=" mt-1 block w-full rounded-md border-gray-300 p-2 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                        />
                      </div>
                    </div>
                  </div>
                  <div>
                    <p className="font-md my-4 text-red-500">{error}</p>
                  </div>
                  <div className="bg-gray-50 px-4 py-3 text-right sm:px-6">
                    <button
                      type="submit"
                      value="Submit"
                      className="inline-flex justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                    >
                      Add
                    </button>
                    <button
                      type="submit"
                      value="Submit"
                      onClick={() => setAdding(false)}
                      className="ml-4 inline-flex justify-center rounded-md border border-transparent bg-yellow-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
