import React, { useState, useEffect } from "react";
import { gql, useQuery } from "@apollo/client";
import { styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Sidebar from "../../components/Sidebar";
import { Link } from "react-router-dom";
import plan from "./plan.jpg"

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#22C55E",
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const GET_PLAN = gql`
  query getPlan($planFor: String!) {
    plans(planFor: $planFor) {
      id
      name
      planFor
      rate
      amount
      wallet
      credits
      validity
    }
  }
`;

const USER_GET = gql`
  query getUser($uid: String!) {
    user(uid: $uid) {
      uid
      name
      email
      mobile
      password
      address
      companyName
      organisationType
      erpType
      bankName
      country
      doj
      planType
      userType
      expireInDays
      wallet
      totalMessages
      totalMessagesSent
      isConnected
    }
  }
`;

const columns = [
  { id: "name", label: "Plan Name", minWidth: 170, align: "left" },
  {
    id: "rate",
    label: "Rate",
    minWidth: 130,
    align: "left",
    format: (value) => value.toLocaleString("en-IN"),
  },
  {
    id: "amount",
    label: "Amount",
    minWidth: 130,
    align: "center",
  },
  {
    id: "wallet",
    label: "Wallet",
    minWidth: 130,
    align: "center",
  },
  {
    id: "credits",
    label: "Credits",
    minWidth: 130,
    align: "right",
  },
  {
    id: "validity",
    label: "Validity",
    minWidth: 130,
    align: "center",
  },
  {
    id: "action",
    label: "Actions",
    minWidth: 130,
    align: "right",
  },
];

export default function Plans() {
  const [resellerPlansData, setResellerPlansData] = useState(null);
  const [userPlansData, setUserPlansData] = useState(null);
  const [userProfile, setUserProfile] = useState(null);
  let { uid } = JSON.parse(localStorage.getItem("user"));

  //GRAPHQL USER QUERY START

  const { data, error, loading } = useQuery(USER_GET, {
    variables: { uid: uid },
  });

  //GRAPHQL USER QUERY END

  //Userplan Fetch Start

  const {
    data: userPlans,
    loading: userPlanLoading,
    error: userPlanError,
  } = useQuery(GET_PLAN, {
    variables: { planFor: "User" },
  });

  //Userplan Fetch End

  //Resellerplan Fetch End

  const {
    data: resellerPlans,
    loading: resellerPlanLoading,
    error: resellerPlanError,
  } = useQuery(GET_PLAN, {
    variables: { planFor: "Reseller" },
  });

  useEffect(() => {
    let userProfile = null;
    if (data) {
      userProfile = data.user;
      setUserProfile(userProfile);
    }
  }, [data, loading]);

  useEffect(() => {
    if (resellerPlans && userProfile) {
      let rPlan = resellerPlans.plans;

      if (userProfile.email === "purva.agrawal@thillais.com") {
        rPlan = rPlan.filter(p => {
          if (p) {
            return !p.name.includes("UL")
          }
        })
      }
      setResellerPlansData(rPlan);
    }
  }, [userProfile, resellerPlans, resellerPlanLoading]);

  useEffect(() => {
    if (userPlans && userProfile) {
      let uPlan = userPlans.plans;

      if (userProfile.email === "purva.agrawal@thillais.com") {
        uPlan = uPlan.filter(p => {
          if (p) {
            return !p.name.includes("UL")
          }
        })
      }
      setUserPlansData(uPlan);
    }
  }, [userProfile, userPlans, userPlanLoading]);

  //Resellerplan Fetch End

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    let val = event.target.value ? +event.target.value : 0;
    setRowsPerPage(val);
    setPage(0);
  };

  const ActionButton = (props) => {

    return (
      <>
        {
          props.value.name !== "DEMO" &&
          (
            <Link
              to="/order"
              style={{
                pointerEvents: props.qrcodelinkenable ? "none" : "all",
              }}
              state={{ ...props.value, planType: props.value.name }}
            >
              Buy Now!
            </Link>
          )
        }

      </>
    );
  };

  return (
    <div className="flex w-screen ">
      <Sidebar />
      <div className="body w-64 flex-1  p-8">
        <Paper sx={{ width: "100%", overflow: "hidden" }}>
          {!resellerPlanLoading && (
            <div className="mb-4">
              <h1 className="mb-4 rounded p-2 text-center text-2xl font-semibold tracking-wider text-green-300 shadow-xl">
                RESELLER PLANS
              </h1>
              <div className="w-full m-auto">
                {/* <img className="m-auto" src={plan} alt="plan" /> */}
              </div>
              <div>
                <TableContainer sx={{ maxHeight: 500 }}>
                  <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                      <TableRow>
                        {columns.map((column) => (
                          <StyledTableCell
                            key={column.id}
                            align={column.align}
                            style={{ minWidth: column.minWidth }}
                          >
                            {column.label}
                          </StyledTableCell>
                        ))}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {resellerPlansData &&
                        resellerPlansData.length > 0 &&
                        resellerPlansData
                          .slice(
                            page * rowsPerPage,
                            page * rowsPerPage + rowsPerPage
                          )
                          .map((row) => {
                            return (
                              <TableRow
                                hover
                                role="checkbox"
                                tabIndex={-1}
                                key={row.id}
                              >
                                {columns.map((column) => {
                                  const value = row[column.id];
                                  return (
                                    <TableCell
                                      key={column.id}
                                      align={column.align}
                                    >
                                      {
                                        column.id === "validity" ? (
                                          "UNLIMITED"
                                        ) :
                                          column.id === "rate" ? (
                                            value + " (Paise)"
                                          ) : column.id === "action" ? (
                                            <ActionButton value={row} />
                                          ) : column.format &&
                                            typeof value === "number" ? (
                                            column.format(value)
                                          ) : (
                                            value
                                          )}
                                    </TableCell>
                                  );
                                })}
                              </TableRow>
                            );
                          })}
                    </TableBody>
                  </Table>
                </TableContainer>
                <TablePagination
                  rowsPerPageOptions={[10, 25, 100]}
                  component="div"
                  count={
                    resellerPlansData && resellerPlansData.length
                      ? resellerPlansData.length
                      : 0
                  }
                  rowsPerPage={rowsPerPage ? rowsPerPage : 0}
                  page={page ? page : 0}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              </div>
            </div>
          )}
          {!userPlanLoading && (
            <div className="my-4">
              <h1 className="mb-4 rounded  p-2 text-center text-2xl font-semibold tracking-wider text-green-300 shadow-xl">
                USER PLANS
              </h1>
              <div>
                <TableContainer sx={{ maxHeight: 500 }}>
                  <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                      <TableRow>
                        {columns.map((column) => (
                          <StyledTableCell
                            key={column.id}
                            align={column.align}
                            style={{ minWidth: column.minWidth }}
                          >
                            {column.id !== "action" ? column.label : ""}
                          </StyledTableCell>
                        ))}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {userPlansData &&
                        userPlansData.length > 0 &&
                        userPlansData
                          .slice(
                            page * rowsPerPage,
                            page * rowsPerPage + rowsPerPage
                          )
                          .map((row) => {
                            return (
                              <TableRow
                                hover
                                role="checkbox"
                                tabIndex={-1}
                                key={row.id}
                              >
                                {columns.map((column) => {
                                  const value = row[column.id];
                                  return (
                                    <TableCell
                                      key={column.id}
                                      align={column.align}
                                    >
                                      {column.id === "rate" ? (
                                        value + " (Paise)"
                                      ) : column.id === "action" ? (
                                        <></>
                                      ) : column.format &&
                                        typeof value === "number" ? (
                                        column.format(value)
                                      ) : (
                                        value
                                      )}
                                    </TableCell>
                                  );
                                })}
                              </TableRow>
                            );
                          })}
                    </TableBody>
                  </Table>
                </TableContainer>
                <TablePagination
                  rowsPerPageOptions={[10, 25, 100]}
                  component="div"
                  count={
                    userPlansData && userPlansData.length
                      ? userPlansData.length
                      : 0
                  }
                  rowsPerPage={rowsPerPage ? rowsPerPage : 0}
                  page={page ? page : 0}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              </div>
            </div>
          )}
        </Paper>
      </div>
    </div>
  );
}
