import React, { useState, useEffect, useRef } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Sidebar from "../../components/Sidebar";
import profile from "./profile.png";
import { Pages, QrCode, VisibilityOff, Visibility } from "@mui/icons-material";
import LinkOffIcon from "@mui/icons-material/LinkOff";
import EditIcon from "@mui/icons-material/Edit";
import { gql, useQuery, useMutation, useLazyQuery } from "@apollo/client";
import moment from "moment";
import axios from "axios";

const USER_GET = gql`
  query getUser($uid: String!) {
    user(uid: $uid) {
      uid
      resellerId
      name
      companyName
      organisationType
      email
      mobile
      password
      address
      doj
      country
      planType
      userType
      expireInDays
      wallet
      totalMessages
      totalMessagesSent
      isConnected
        devices {
        id
        deviceId
        deviceName
        isConnected
        updatedAt
      }
      orders {
        orderId
        name
        amount
        status
        createdAt
      }
    }
  }
`;

const USER_UPDATE = gql`
  mutation ($userId: ID!, $user: UserInput!) {
    userUpdate(userId: $userId, user: $user) {
      userErrors {
        message
      }
      user {
        name
      }
    }
  }
`;

const GET_PLAN_BY_NAME = gql`
  query ($planName: String!) {
    PlanByName(planName: $planName) {
      id
      name
      type
      rate
      credits
      wallet
      planFor
      amount
      validity
      description
    }
  }
`;

function UserProfile() {
  const location = useLocation();

  const [userProfile, setUserProfile] = useState(null);

  const [isVisibility, setIsVisibility] = useState(false);
  const [isConfirmVisibility, setIsConfirmVisibility] = useState(false);
  const passwordRef = useRef();
  const confirmPasswordRef = useRef();

  const [errorMessage, setErrorMessage] = useState(null);
  const [successMessage, setSuccessMessage] = useState(null);

  const [resellerInfo, setResellerInfo] = useState(null);

  const navigate = useNavigate();

  let planName = "";

  const userLocalInfo = JSON.parse(localStorage.getItem("user"))
    ? JSON.parse(localStorage.getItem("user"))
    : { uid: "" };

  let { uid } = location.state.uid ? location.state : location.state.value;

  //GRAPHQL USER QUERY START

  const {
    data: userPlanData,
    loading: userPlanLoading,
    refetch: userPlanRefetch,
  } = useQuery(GET_PLAN_BY_NAME, {
    variables: { planName },
  });

  const { data, error, loading, refetch } = useQuery(USER_GET, {
    variables: { uid: uid },
  });

  const [
    getResellerInfo,
    { data: resellerData, error: resellerError, loading: resellerLoading },
  ] = useLazyQuery(USER_GET, {
    variables: { uid: uid },
  });

  //GRAPHQL USER QUERY END

  //GRAPHQL USER CHANGE PASSWORD START
  const [
    changePassword,
    {
      data: userPasswordData,
      loading: userPasswordLoading,
      error: userPasswordError,
    },
  ] = useMutation(USER_UPDATE, {
    refetchQueries: [
      USER_GET,
      {
        variables: { uid: uid },
      },
    ],
  });
  //GRAPHQL USER CHANGE PASSWORD END

  useEffect(() => {
    if (userProfile) {
      userPlanRefetch({ planName: userProfile.planType });
    }
  }, [userProfile]);

  useEffect(() => {
    let userProfile = null;
    if (data) {
      userProfile = data.user;
      setUserProfile(userProfile);

      if (userProfile.resellerId)
        getResellerInfo({
          variables: {
            uid: userProfile?.resellerId,
          },
        });
    }
  }, [data, loading]);

  useEffect(() => {
    refetch();
  }, []);

  const changePasswordHandler = () => {
    if (
      passwordRef.current.value &&
      confirmPasswordRef.current.value &&
      passwordRef.current.value !== "" &&
      confirmPasswordRef.current.value !== "" &&
      passwordRef.current.value === confirmPasswordRef.current.value
    ) {
      changePassword({
        variables: {
          userId: userProfile.uid,
          user: {
            password: passwordRef.current.value,
          },
        },
      });

      setErrorMessage("");
      setSuccessMessage("Password changed successfully!");
      passwordRef.current.value = "";
      confirmPasswordRef.current.value = "";
    } else {
      setErrorMessage("Password is not valid");
      setSuccessMessage("");
    }
  };

  const handleResetDevice = (id) => {
    let config = {
      method: "get",
      url: `https://messageapi.in/client/removeClient/${id}`,
      headers: {},
    };

    axios(config)
      .then((response) => {
        // console.log(JSON.stringify(response.data));

        navigate("/users");
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    if (resellerData) setResellerInfo(resellerData.user);
  }, [resellerLoading, resellerData]);

  return (
    <div className="flex w-screen ">
      <Sidebar />
      {!loading && userProfile && (
        <div className="body h-screen w-64 flex-1 overflow-y-scroll p-8">
          <h1 className="text-purple-500-600 mb-8 text-left text-4xl font-thin uppercase leading-10">
            Hello {userProfile.name} !
          </h1>
          <div className="flex">
            <div className="flex-1">
              <div className="items-left flex flex-col justify-center space-y-1">
                <img className="mb-8 w-24 rounded-full" src={profile} alt="" />
                <div className="mb-4 flex w-full items-center justify-start space-x-2">
                  <div
                    className={
                      userProfile?.devices[0]?.isConnected
                        ? "inline-block  h-4 w-4 rounded-full bg-green-500"
                        : "inline-block  h-4 w-4 rounded-full bg-red-500"
                    }
                  ></div>
                  <p
                    className={
                      userProfile?.devices[0]?.isConnected
                        ? " rounded-full font-mono text-green-500"
                        : " rounded-full font-mono text-red-500"
                    }
                  >
                    {userProfile?.devices[0]?.isConnected}
                  </p>
                </div>
                <p className="text-xl font-thin uppercase text-purple-600">
                  Name:
                  <span className="ml-2 text-gray-600">{userProfile.name}</span>
                </p>
                <p className="text-xl font-thin uppercase text-purple-600">
                  Company Name:
                  <span className="ml-2 text-gray-600">
                    {userProfile.companyName}
                  </span>
                </p>
                <p className="text-xl font-thin uppercase text-purple-600">
                  Company Type:
                  <span className="ml-2 text-gray-600">
                    {userProfile.organisationType}
                  </span>
                </p>
                <p className="text-xl font-thin uppercase text-purple-600">
                  User ID:
                  <span className=" ml-2 normal-case text-gray-600">
                    {userProfile.uid}
                  </span>
                </p>
                {resellerInfo?.name && resellerInfo?.name !== "" && (
                  <p className="bg-gray-300 text-xl font-thin uppercase text-purple-600">
                    Reseller Info:
                    <span className=" ml-2 normal-case text-gray-600">
                      {resellerInfo.name + " - " + resellerInfo.mobile}
                    </span>
                  </p>
                )}
                <p className="text-xl font-thin uppercase text-purple-600">
                  Joining Date:
                  <span className=" ml-2 text-gray-600">
                    {moment(new Date(Number(userProfile.doj)))
                      .utc()
                      .format("DD-MM-YYYY")}
                  </span>
                </p>
              </div>
            </div>

            <div className="flex-1">
              <div className="mb-4">
                <p className="text-xl font-thin uppercase text-purple-600">
                  Username:
                  <span className="ml-2 normal-case text-gray-600">
                    {userProfile.email}
                  </span>
                </p>
              </div>
              <h1 className="text-xl font-thin uppercase text-purple-600">
                ADDRESS
              </h1>
              <p className="text-xl font-thin uppercase text-gray-600">
                {userProfile.address}
              </p>
              <p className="text-xl font-thin uppercase text-gray-600">
                {userProfile.mobile}
              </p>
              <p className="text-xl font-thin uppercase text-gray-600">
                {userProfile.email}
              </p>
              <p className="text-xl font-thin uppercase text-gray-600">
                {userProfile.country}
              </p>
            </div>
          </div>
          <hr className="mt-4 mb-4" />
          <div className="flex">
            <div className="flex-1">
              <div className="items-left flex flex-col justify-center space-y-1">
                <p className="text-xl font-thin uppercase text-purple-600">
                  Plan Type:
                  <span className="ml-2 text-gray-600">
                    {userProfile.planType}
                  </span>
                </p>
                <p className="text-xl font-thin uppercase text-purple-600">
                  User Type:
                  <span className=" ml-2 text-gray-600">
                    {userProfile.userType}
                  </span>
                </p>
                <p className="text-xl font-thin uppercase text-purple-600">
                  Expire Date:
                  <span className=" ml-2 text-gray-600">
                    {moment(Number(userProfile.doj))
                      .add(userProfile.expireInDays, "days")
                      .utc()
                      .format("DD-MM-YYYY")}
                  </span>
                </p>
              </div>
            </div>
            <div className="flex-1">
              <p className="text-xl font-thin uppercase text-purple-600">
                Purchased Credits:
                <span className=" ml-2 text-gray-600">
                  {userProfile.totalMessages}
                </span>
              </p>
              <p className="text-xl font-thin uppercase text-purple-600">
                Used Credits:
                <span className=" ml-2 text-gray-600">
                  {userProfile.totalMessagesSent}
                </span>
              </p>
              {Number(userProfile.wallet) === 0 && (
                <p className="text-xl font-thin uppercase text-purple-600">
                  Remaining Credits:
                  <span className=" ml-2 text-gray-600">
                    {userProfile.totalMessages - userProfile.totalMessagesSent}
                  </span>
                </p>
              )}
              {!userPlanLoading && userPlanData && (
                <div className="justify-left flex  items-center space-x-2">
                  <p className="text-xl font-thin uppercase text-purple-600">
                    Plan{" "}
                  </p>
                  <p> : </p>
                  <span className=" ml-2 text-gray-600">
                    {userPlanData.PlanByName.name}
                  </span>
                </div>
              )}

              {!userPlanLoading && userPlanData && (
                <div className="justify-left flex  items-center space-x-2">
                  <p className="text-xl font-thin uppercase text-purple-600">
                    Plan Type
                  </p>
                  <p> : </p>
                  <span className=" ml-2 text-gray-600">
                    {userPlanData.PlanByName.type}
                  </span>
                </div>
              )}
            </div>
          </div>
          <hr className="mt-4 mb-4" />

          <hr className="mt-4 mb-4" />
          <h3 className="text-md my-4 text-red-500">{errorMessage}</h3>
          <h3 className="text-md my-4 text-green-500">{successMessage}</h3>

          <hr className="mt-4 mb-4" />
          <div className="mt-4 flex">
            <div className="flex-1 text-right">
              <Link
                className="ml-4 bg-yellow-400 px-8 py-2 text-white"
                to="/users"
                state={{ ["uid"]: userLocalInfo.uid }}
              >
                Back
              </Link>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default UserProfile;
