import React from 'react'
import Sidebar from '../../components/Sidebar'


function ReturnPolicy() {
    return (
        <div className="flex w-screen">
            <Sidebar />
            <div className="body w-full flex-1 bg-gray-200 p-8">
                <div class="mx-auto mb-24 flex w-full flex-col flex-wrap items-center justify-center  md:h-screen">
                    <h1 class="mt-24 text-4xl font-bold">Return and Cancellation Policy</h1>
                    <div class="m-auto mt-12 flex h-1/2 w-1/3 flex-col items-center divide-y-2 divide-dashed rounded-2xl bg-white p-8">
                        <p>Licensee warrants that he inspected The Software according to clause 7(c) and that it is adequate to his needs.Accordingly, as The Software is intangible goods, Licensee shall not be, ever, entitled to any refund, rebate, compensation or restitution for any reason Upto whatsoever, even if The Software contains material flaws.</p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ReturnPolicy