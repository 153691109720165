import React, { useState, useEffect, useRef } from "react";

import { VisibilityOff, Visibility } from "@mui/icons-material";
import Sidebar from "../../components/Sidebar";
import profile from "./profile.png";
import { gql, useMutation, useQuery } from "@apollo/client";

import { Link } from "react-router-dom";
import moment from "moment";

const USER_GET = gql`
  query getUser($uid: String!) {
    user(uid: $uid) {
      uid
      name
      email
      mobile
      password
      address
      companyName
      organisationType
      erpType
      bankName
      country
      doj
      planType
      userType
      expireInDays
      wallet
      totalMessages
      totalMessagesSent
      isConnected
    }
  }
`;

const USER_UPDATE = gql`
  mutation ($userId: ID!, $user: UserInput!) {
    userUpdate(userId: $userId, user: $user) {
      userErrors {
        message
      }
      user {
        name
      }
    }
  }
`;

function Profile() {
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [isVisibility, setIsVisibility] = useState(false);
  const [isConfirmVisibility, setIsConfirmVisibility] = useState(false);
  const passwordRef = useRef(null);
  const confirmPasswordRef = useRef(null);
  const [userProfile, setUserProfile] = useState(null);

  let { uid } = JSON.parse(localStorage.getItem("user"));

  //GRAPHQL USER QUERY START

  const { data, error, loading } = useQuery(USER_GET, {
    variables: { uid: uid },
  });

  //GRAPHQL USER QUERY END

  //GRAPHQL USER CHANGE PASSWORD START
  const [
    changePassword
  ] = useMutation(USER_UPDATE, {
    refetchQueries: [
      USER_GET,
      {
        variables: { uid: uid },
      },
    ],
  });
  //GRAPHQL USER CHANGE PASSWORD END

  useEffect(() => {
    let userProfile = null;
    if (data) {
      userProfile = data.user;
      setUserProfile(userProfile);
    }
  }, [data, loading]);

  const filterText = (str) => {
    if (str && str !== "" && typeof str === "string") {
      let txt = str
        .replaceAll(/Firebase:|Error|Auth/gi, "")
        .replace(/[^a-zA-Z ]/gi, " ")
        .trim();
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    }

    return;
  };

  const changePasswordHandler = () => {
    if (
      passwordRef.current.value &&
      confirmPasswordRef.current.value &&
      passwordRef.current.value !== "" &&
      confirmPasswordRef.current.value !== "" &&
      passwordRef.current.value === confirmPasswordRef.current.value
    ) {
      let user = JSON.parse(window.localStorage.getItem("user"));

      if (user) {

        changePassword({
          variables: {
            userId: userProfile.uid,
            user: {
              password: passwordRef.current.value,
            },
          },
        });

        setErrorMessage("");
        setSuccessMessage("Password changed successfully!");
        passwordRef.current.value = "";
        confirmPasswordRef.current.value = "";
      }
    } else {
      setSuccessMessage("");
      setErrorMessage("Password is not valid");
    }
  };

  useEffect(() => {
    setTimeout(() => {
      setErrorMessage("");
      setSuccessMessage("");
    }, 1000);
  }, [errorMessage, successMessage]);

  return (
    <div className="flex w-screen h-screen overflow-y-scroll">
      <Sidebar />
      <div className="flex w-screen h-screen overflow-y-scroll">
        {!loading && userProfile && (
          <div className="body bg-black-50 w-64 flex-1 p-8">
            <h1 className="text-purple-500-600 mb-8 text-left text-4xl font-thin uppercase leading-10">
              {userProfile.name}
            </h1>
            <div className="flex">
              <div className="flex-1">
                <div className="items-left flex flex-col justify-center space-y-1">
                  <img className="mb-8 w-24 rounded-full" src={profile} alt="" />
                  <div className="mb-4 flex w-full items-center justify-start space-x-2">
                    <div
                      className={
                        userProfile.isConnected
                          ? "inline-block  h-4 w-4 rounded-full bg-green-500"
                          : "inline-block  h-4 w-4 rounded-full bg-red-500"
                      }
                    ></div>
                    <p
                      className={
                        userProfile.isConnected
                          ? " rounded-full font-mono text-green-500"
                          : " rounded-full font-mono text-red-500"
                      }
                    >
                      {userProfile.isConnected}
                    </p>
                  </div>
                  <p className="text-xl font-thin uppercase text-orange-600">
                    Name:
                    <span className="text-black-600 ml-2">
                      {userProfile.name}
                    </span>
                  </p>
                  <p className="text-xl font-thin uppercase text-orange-600">
                    User ID:
                    <span className=" text-black-600 ml-2 normal-case">
                      {userProfile.uid}
                    </span>
                  </p>
                  <p className="text-xl font-thin uppercase text-orange-600">
                    Joining Date:
                    <span className=" text-black-600 ml-2">
                      {moment(new Date(Number(userProfile.doj)))
                        .utc()
                        .format("DD-MM-YYYY")}
                    </span>
                  </p>
                </div>
              </div>

              <div className="flex-1">
                <div className="mb-4">
                  <h1 className="text-xl font-thin uppercase text-orange-600">
                    BUSINESS DETAILS
                  </h1>
                  <p className="text-black-600 text-xl font-thin uppercase">
                    {userProfile.companyName}
                  </p>
                  <p className="text-black-600 text-xl font-thin uppercase">
                    {userProfile.organisationType}
                  </p>
                  <p className="text-black-600 text-xl font-thin uppercase">
                    {userProfile.erpType}
                  </p>
                  <p className="text-black-600 text-xl font-thin uppercase">
                    {userProfile.bankName}
                  </p>
                </div>
                <h1 className="text-xl font-thin uppercase text-orange-600">
                  ADDRESS
                </h1>
                <p className="text-black-600 text-xl font-thin uppercase">
                  {userProfile.address}
                </p>
                <p className="text-black-600 text-xl font-thin uppercase">
                  {userProfile.mobile}
                </p>
                <p className="text-black-600 text-xl font-thin uppercase">
                  {userProfile.email}
                </p>
                <p className="text-black-600 text-xl font-thin uppercase">
                  {userProfile.country}
                </p>
              </div>
            </div>
            <hr className="mt-4 mb-4" />
            <div className="flex">
              <div className="flex-1">
                <div className="items-left flex flex-col justify-center space-y-1">
                  <p className="text-xl font-thin uppercase text-orange-600">
                    Plan Type:
                    <span className="text-black-600 ml-2">
                      {userProfile.planType}
                    </span>
                  </p>
                  <p className="text-xl font-thin uppercase text-orange-600">
                    User Type:
                    <span className=" text-black-600 ml-2">
                      {userProfile.userType}
                    </span>
                  </p>
                  <p className="text-xl font-thin uppercase text-orange-600">
                    Expire Date:
                    <span className=" text-black-600 ml-2">
                      UNLIMITED
                    </span>
                  </p>
                </div>
              </div>
              <div className="flex-1">
                <p className="text-xl font-thin uppercase text-orange-600">
                  Purchased Credits:
                  <span className=" text-black-600 ml-2">
                    {userProfile.totalMessages}
                  </span>
                </p>
                <p className="text-xl font-thin uppercase text-orange-600">
                  Used Credits:
                  <span className=" text-black-600 ml-2">
                    {userProfile.totalMessagesSent}
                  </span>
                </p>
                <p className="text-xl font-thin uppercase text-orange-600">
                  Remaining Credits:
                  <span className=" text-black-600 ml-2">
                    {Number(userProfile.totalMessages) -
                      Number(userProfile.totalMessagesSent)}
                  </span>
                </p>
                <p className="text-xl font-thin uppercase text-orange-600">
                  Wallet:
                  <span className=" text-black-600 ml-2">
                    {Number(userProfile.wallet)}
                  </span>
                </p>
              </div>
            </div>
            <hr className="mt-4 mb-4" />
            <div className="grid grid-cols-2">
              <div className="mb-4">
                <p className="text-xl font-thin uppercase text-orange-600">
                  Username:
                  <span className="text-black-600 ml-2 lowercase">
                    {userProfile.email}
                  </span>
                </p>
                <p className="text-xl font-thin uppercase text-orange-600">
                  Password:
                  <span className=" text-black-600 ml-2 normal-case">
                    {userProfile.password}
                  </span>
                </p>
              </div>
              <div className="flex flex-col align-center w-full m-auto">
                <p className="mb-4 text-center text-xl font-bold text-red-600">
                  {errorMessage && errorMessage !== ""
                    ? filterText(errorMessage)
                    : successMessage && successMessage !== ""
                      ? filterText(successMessage)
                      : ""}
                </p>
                <div className="my-4 flex w-[40%] flex-col align-center justify-end">
                  <div className="relative flex sm:flex-col sm:items-center sm:justify-center">
                    <label
                      htmlFor="password"
                      className="text-xl font-thin uppercase text-orange-600"
                    >
                      Password
                    </label>
                    <input
                      type="password"
                      className="border-black-600 ml-2 rounded-md border-2 p-1"
                      name="password"
                      defaultValue={
                        passwordRef.current ? passwordRef.current.value : ""
                      }
                      ref={passwordRef}
                    />
                    <label htmlFor="peye" className="absolute top-8 right-0">
                      {isVisibility && <Visibility />}
                      {!isVisibility && <VisibilityOff />}

                      <input
                        className="invisible"
                        type="checkbox"
                        id="peye"
                        onChange={() => {
                          setIsVisibility(!isVisibility);
                          if (!isVisibility) passwordRef.current.type = "text";
                          else passwordRef.current.type = "password";
                        }}
                      />
                    </label>
                  </div>
                  <div className="relative flex sm:flex-col sm:items-center sm:justify-center">
                    <label
                      htmlFor="confirmPassword"
                      className="text-xl font-thin uppercase text-orange-600"
                    >
                      Confirm Password
                    </label>
                    <input
                      type="password"
                      className="border-black-600 ml-2 rounded-md border-2 p-1"
                      name="confirmPassword"
                      defaultValue={
                        confirmPasswordRef.current
                          ? confirmPasswordRef.current.value
                          : ""
                      }
                      ref={confirmPasswordRef}
                    />
                    <label htmlFor="cpeye" className="absolute top-8 right-0">
                      {isConfirmVisibility && <Visibility />}
                      {!isConfirmVisibility && <VisibilityOff />}

                      <input
                        type="checkbox"
                        id="cpeye"
                        className="invisible"
                        onChange={() => {
                          setIsConfirmVisibility(!isConfirmVisibility);
                          if (!isConfirmVisibility)
                            confirmPasswordRef.current.type = "text";
                          else confirmPasswordRef.current.type = "password";
                        }}
                      />
                    </label>
                  </div>
                  <div className="mt-2 flex items-center justify-end space-x-2">

                    <button
                      className=" bg-blue-800 py-2 px-8  text-white"
                      onClick={() => changePasswordHandler()}
                    >
                      Change
                    </button>


                    <Link
                      className=" bg-yellow-400 px-8 py-2 text-white"
                      to="/users"
                    >
                      Cancel
                    </Link>

                  </div>
                </div>
                <p className="font-md my-4 text-red-500">{error}</p>

              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default Profile;
