import axios from "axios";
import React, { useEffect, useState, useRef, useCallback } from "react";
import Sidebar from "../../components/Sidebar";
import Emojis from "../../util/Emojis";

function SingleMessage() {
  const userInfo = localStorage.getItem("user")
    ? JSON.parse(localStorage.getItem("user"))
    : { uid: "" };
  const [message, setMessage] = useState({});
  const [status, setStatus] = useState("");
  const [error, setError] = useState("");
  const [selectedFile, setSelectedFile] = useState();
  const [isSelected, setIsSelected] = useState(false);

  const [isClicked, setIsClicked] = useState(false);

  const phoneRef = useRef();
  const messageRef = useRef();
  const fileRef = useRef();

  const cursorRef = useRef();

  const handleMessage = (e) => {
    setMessage((prevData) => {
      return { ...prevData, [e.target.name]: e.target.value };
    });
  };

  const changeHandler = (event) => {
    setSelectedFile(event.target.files[0]);
    setIsSelected(true);
  };

  const getSelectedEmoji = (selectedEmoji) => {
    // messageRef.current.value += selectedEmoji
    messageRef.current.value = messageRef.current.value.insert(cursorRef.current, ` ${selectedEmoji}`);
  }

  useEffect(() => {
    setTimeout(() => {
      setStatus("");
      setError("");
      setIsClicked(false);
      setSelectedFile({});
      setIsSelected(false);
    }, 1000);
  }, [error, status]);

  const sendFileMessage = () => {
    const apiURL = `https://messageapi.in/chat/sendmessagefile/${userInfo.uid}`;
    const formData = new FormData();

    formData.append("id", userInfo.uid);
    formData.append("file", selectedFile);
    formData.append("message", message.message);
    formData.append("phone", message.phone.replace(/[^0-9]|\s+/g, "").trim());

    fetch(apiURL, {
      method: "POST",
      headers: {},
      body: formData,
    })
      .then((response) => response.json())
      .then((result) => {
        let msgResp = result.message;
        let msgStatus = result.status;

        if (msgStatus !== "error") {
          setStatus(msgResp);
          phoneRef.current.value = "";
          messageRef.current.value = "";
          fileRef.current.value = "";
          setMessage({});
          setSelectedFile({});
        } else {
          setError(msgResp);
        }
      })
      .catch((error) => {
        console.error("Error:", error);
        setError("You are not authorized to make this request");
      });
  };

  const sendMessage = () => {
    setIsClicked(true);
    let apiURL = "https://messageapi.in/chat/sendmessage";

    if (
      message.phone &&
      message.phone !== "" &&
      message.phone.toString().length === 12
    ) {
      if (message.message && message.message !== "") {
        if (isSelected) {
          sendFileMessage();
        } else {
          var data = JSON.stringify({
            id: userInfo.uid,
            phone: message.phone.replace(/[^0-9]|\s+/g, "").trim(),
            message: message.message,
          });

          var config = {
            method: "POST",
            url: apiURL,
            headers: {
              "Content-type": "application/json",
            },
            data: data,
          };

          axios(config)
            .then(function (response) {
              let msgResp = response.data.message;
              let msgStatus = response.data.status;

              if (msgStatus !== "error") {
                phoneRef.current.value = "";
                messageRef.current.value = "";
                setMessage({});
                setStatus(msgResp);
              } else {
                setError(msgResp);
              }
            })
            .catch(function (error) {
              setError("You are not authorized to make this request");
            });
        }
      } else {
        setError("Message is empty");
      }
    } else {
      setError("Phone is not valid");
    }
  };

  String.prototype.insert = function (index, string) {
    if (index > 0) {
      return this.substring(0, index) + string + this.substr(index);
    }

    return string + this;
  };

  const selFun = useCallback(e => {
    console.log('Caret at: ', e.target.selectionStart)
    cursorRef.current = e.target.selectionStart;
  }, []);

  useEffect(() => {
    document.getElementById('message').addEventListener('click', selFun, false);
    document.getElementById('message').addEventListener('keydown', selFun, false);
    document.getElementById('message').addEventListener('keyup', selFun, false);

    return () => {
      document.removeEventListener("click", selFun, false);
      document.removeEventListener("keydown", selFun, false);
      document.removeEventListener("keyup", selFun, false);
    };

  }, []);

  return (
    <div className="flex w-screen ">
      <Sidebar />
      <div className="body w-full flex-1  p-8">
        <div className="w-full">
          <div className="w-10/12 rounded-md  p-2">
            <div className="head">
              <h1 className="mb-4 text-center text-2xl font-bold tracking-wide text-green-300 text-green-300">
                SEND SINGLE MESSAGE
              </h1>
              <hr className="mb-2" />
              {status !== "" ? (
                <p className="text-center text-xl tracking-normal text-green-500 ">
                  {status}
                </p>
              ) : error !== "" ? (
                <p className="text-center text-xl tracking-normal text-red-500">
                  {error}
                </p>
              ) : (
                ""
              )}
            </div>
            <div className="body flex flex-col items-center justify-center">
              <div
                action=""
                method="post"
                className="flex w-10/12 flex-col space-y-4"
              >
                <label htmlFor="phone" className="text-xl font-thin text-green-300">
                  Phone No:
                </label>
                <input
                  type="number"
                  name="phone"
                  id="phone"
                  className="w-full rounded-md p-2"
                  onChange={handleMessage}
                  ref={phoneRef}
                />

                <label
                  htmlFor="message"
                  className="text-xl font-thin text-green-300"
                >
                  Message:
                </label>
                <textarea
                  name="message"
                  id="message"
                  cols="30"
                  rows="10"
                  className="w-full rounded-md p-2"
                  onChange={handleMessage}
                  ref={messageRef}
                ></textarea>

                <label htmlFor="file" className="text-xl font-thin text-green-300">
                  Upload File:
                </label>
                <input
                  type="file"
                  name="file"
                  id="file"
                  onChange={changeHandler}
                  ref={fileRef}
                />

                {isSelected && selectedFile ? (
                  <div>
                    <p>
                      Filename: {selectedFile.name ? selectedFile.name : ""}
                    </p>
                    <p>
                      Filetype: {selectedFile.type ? selectedFile.type : ""}
                    </p>
                    <p>
                      Size in bytes:{" "}
                      {selectedFile.size ? selectedFile.size : ""}
                    </p>
                    <p>
                      lastModifiedDate:{" "}
                      {selectedFile.lastModifiedDate
                        ? selectedFile.lastModifiedDate.toLocaleDateString()
                        : ""}
                    </p>
                  </div>
                ) : (
                  <p>Select a file to show details</p>
                )}

                <div className="p-2 text-right">
                  <button
                    className="rounded-md bg-yellow-400 py-2 px-12 text-green-300"
                    onClick={sendMessage}
                    disabled={isClicked}
                  >
                    {isClicked ? "Sending..." : "Send"}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Emojis getSelectedEmoji={(data) => getSelectedEmoji(data)} />
    </div>
  );
}

export default SingleMessage;
