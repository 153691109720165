import React from 'react'
import Sidebar from './Sidebar';

function Container({ children }) {
    return (
        <div className="flex">
            {/* Sidebar */}
            <div>
                <Sidebar />
            </div>

            {/* Main Content */}
            <div className="flex-1">
                {/* Content */}
                <div className="h-[calc(100vh-40px)]">
                    {/* Your content */}
                    {children}
                </div>
            </div>
        </div>
    )
}

export default Container
