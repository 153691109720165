import React, { useCallback, useEffect, useState } from "react";
import { useRef } from "react";
import EmojisData from "./EmojisData";

function useEmojis({ getSelectedEmoji }) {
  const [emojis, setEmojis] = useState([]);
  const searchRef = useRef("");
  const allEmojisRef = useRef([]);

  useEffect(() => {
    if (EmojisData) {
      allEmojisRef.current = EmojisData
      setEmojis(allEmojisRef.current)
    }
  }, []);

  let selectEmoji = (e) => {
    getSelectedEmoji(e.target.innerText)
  }

  let searchEmoji = (e) => {
    if (e.key === "Enter") {
      let searchedEmoji = allEmojisRef.current.filter(em => em.unicodeName.toLowerCase().includes(searchRef.current.value));
      console.log("searchedEmoji", searchedEmoji);
      setEmojis(searchedEmoji)
    }
  }

  const escFunction = useCallback((event) => {
    if (event.key === "Escape") {
      setEmojis(allEmojisRef.current)
      searchRef.current.value = ""
    }
  }, []);

  useEffect(() => {
    document.addEventListener("keydown", escFunction, false);

    return () => {
      document.removeEventListener("keydown", escFunction, false);
    };
  }, []);

  return (
    <div className="p-2 bg-gray-100 h-screen  w-[300px] overflow-y-scroll">
      <input type="text" placeholder="May I help You :)" className="rounded-lg p-2 w-full mb-2" ref={searchRef}
        onKeyDown={searchEmoji} />
      <div className="grid grid-cols-5 gap-2 auto-cols-max justify-items-center h-full w-full overflow-auto">
        {emojis.map((emojis) => {
          return <div className="hover:cursor-pointer" key={emojis.slug} onClick={selectEmoji}>{emojis.character}</div>
        })}
      </div>
    </div>
  );
}

export default useEmojis;
