import React, { useState } from "react";
import QrCode2Icon from "@mui/icons-material/QrCode2";
import WebhookIcon from "@mui/icons-material/Webhook";
import {
  Group,
  PersonPin,
  Dashboard,
  ExitToApp,
  WindowSharp,
  Pages,
} from "@mui/icons-material";
import SummarizeIcon from "@mui/icons-material/Summarize";
import ExtensionIcon from "@mui/icons-material/Extension";
import LockResetIcon from "@mui/icons-material/LockReset";
import MessageIcon from "@mui/icons-material/Message";
import ChatIcon from "@mui/icons-material/Chat";
import { Link, useNavigate } from "react-router-dom";
import ReceiptIcon from "@mui/icons-material/Receipt";
import axios from "axios";

function Sidebar(props) {
  const navigate = useNavigate();

  const [isProcessing, setIsProcessing] = useState(false);
  const userInfo = localStorage.getItem("user")
    ? JSON.parse(localStorage.getItem("user"))
    : { uid: "" };

  const refreshQRCode = () => {
    setIsProcessing(true)
    var config = {
      method: 'get',
      url: `https://messagesapi.co.in/client/forcelyRemoveClient/${userInfo?.uid}`,
      headers: {
        'Content-Type': 'application/json'
      }
    }

    axios(config)
      .then(function () {
        setIsProcessing(false)
        navigate('/dashboard', { replace: true })
        navigate(0)
      })
      .catch(function () {
        setIsProcessing(false)
      })
  }

  return (
    <div className="sidebar h-screen w-60 bg-green-800 overflow-y-scroll">
      <ul className="space-y-4 p-4 text-white">
        <li className="mb-10 text-2xl font-semibold text-white uppercase">
          <Link to="/">Message API</Link>
        </li>
        <li>
          <Link to="/">
            <Dashboard />
            <span className="ml-4">Dashboard</span>
          </Link>
        </li>
        <li>
          <Link to="/profile">
            <PersonPin />
            <span className="ml-4">Profile</span>
          </Link>
        </li>
        {/* <li>
          <Link
            to="/qrcode"
            style={{
              pointerEvents: props.qrcodelinkenable ? "none" : "all",
            }}
            state={{ ["uid"]: userInfo.uid }}
          >
            <QrCode2Icon></QrCode2Icon>
            <span className="ml-4">
              {props.qrcodelinkenable ? "Wait..." : "Get QR Code"}
            </span>
          </Link>
        </li> */}
        <li>
          <a href="https://documenter.getpostman.com/view/1240173/2sAXxLDEz7">
            <WebhookIcon></WebhookIcon>
            <span className="ml-4">API</span>
          </a>
        </li>
        <li>
          <Link to="/plugin">
            <ExtensionIcon></ExtensionIcon>
            <span className="ml-4">Plugin</span>
          </Link>
        </li>
        <li onClick={() => {
          if (typeof props.resetOrder === "function")
            props.resetOrder()
        }
        }>
          <Link to="/order">
            <ExtensionIcon></ExtensionIcon>
            <span className="ml-4">My Orders</span>
          </Link>
        </li>
        <li>
          <Link to="/plans">
            <ReceiptIcon />
            <span className="ml-4">Plans</span>
          </Link>
        </li>
        <li onClick={() => {
          if (typeof props.resetUser === "function") props.resetUser()
        }}>
          <Link to="/users" state={{ ["id"]: userInfo.uid }}>
            <Group />
            <span className="ml-4">Users</span>
          </Link>
        </li>
        {/* <li>
          <Link to="/singlemessage">
            <MessageIcon></MessageIcon>
            <span className="ml-4">Single Message</span>
          </Link>
        </li>
        <li>
          <Link to="/multimessage">
            <ChatIcon></ChatIcon>
            <span className="ml-4">Multi Message</span>
          </Link>
        </li>
        <li>
          <Link to="/report/delivery" state={{ ["uid"]: userInfo.uid }}>
            <SummarizeIcon />
            <span className="ml-4">Delivery Report</span>
          </Link>
        </li>
        <li>
          <Link to="/privacy">
            <Pages />
            <span className="ml-4">Privacy Policy</span>
          </Link>
        </li>
        <li>
          <Link to="/terms">
            <Pages />
            <span className="ml-4">Terms & Conditions</span>
          </Link>
        </li>
        <li>
          <Link to="/returnpolicy">
            <Pages />
            <span className="ml-4">Return Policy</span>
          </Link>
        </li>
        <li>
          <button
            onClick={() => {
              refreshQRCode();
            }}
            disabled={isProcessing}
          >
            <LockResetIcon />
            <span className="ml-4">
              {!isProcessing ? "Reset QR" : "Wait..."}
            </span>
          </button>
        </li> */}
        <li className="absolute bottom-10">
          <Link
            onClick={() => {
              window.localStorage.clear("user");
              navigate("/login", { replace: true });
              navigate(0);
            }}
            to="/login"
          >
            <ExitToApp></ExitToApp>
            <span className="ml-4">Sign Out</span>
          </Link>
        </li>
      </ul>
    </div>
  );
}

export default Sidebar;
