import React, { useState, useEffect } from "react";
import { styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Sidebar from "../../components/Sidebar";
import { gql, useQuery } from "@apollo/client";
import moment from "moment";
import { useLocation } from "react-router-dom";

const GET_DELIVERY_REPORT = gql`
  query getDeliveryReports($uid: String) {
    deliveryReports(uid: $uid) {
      userId
      from
      to
      message
      attachment
      status
      createdAt
    }
  }
`;

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#22C55E",
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const columns = [
  { id: "createdAt", label: "Date Time", minWidth: 130, align: "left" },
  { id: "message", label: "Message", minWidth: 130, align: "left" },
  { id: "from", label: "From", minWidth: 100, align: "center" },
  { id: "to", label: "To", minWidth: 100, align: "center" },
  {
    id: "attachment",
    label: "Attachment",
    minWidth: 130,
    align: "left",
    format: (value) => value.toLocaleString("en-IN"),
  },
  {
    id: "status",
    label: "Status",
    minWidth: 130,
    align: "right",
    format: (value) => value.toLocaleString("en-IN"),
  },
];

export default function Delivery() {
  const [page, setPage] = React.useState(0);
  const [data, setData] = useState(null);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const location = useLocation();
  let { uid } = JSON.parse(localStorage.getItem("user"));
  let userUID = null;

  uid = location?.state?.uid;

  const {
    data: deliveryReportsData,
    loading: deliveryReportsLoading,
    error: deliveryReportsError,
  } = useQuery(GET_DELIVERY_REPORT, {
    variables: { uid: uid },
  });

  useEffect(() => {
    if (deliveryReportsData) {
      setData(deliveryReportsData.deliveryReports);
    }
  }, [deliveryReportsData, deliveryReportsLoading]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return (
    <div className="flex w-screen ">
      <Sidebar />
      {!deliveryReportsLoading && data && (
        <div className="body w-64 flex-1  p-8">
          <h1 className="mb-4 rounded  p-2 text-center text-2xl font-semibold tracking-wider text-green-300">
            DELIVERY REPORT
          </h1>
          <Paper sx={{ width: "100%", overflow: "hidden" }}>
            <TableContainer sx={{ maxHeight: 600 }}>
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    {columns.map((column) => (
                      <StyledTableCell
                        key={column.id}
                        align={column.align}
                        style={{ minWidth: column.minWidth }}
                      >
                        {column.label}
                      </StyledTableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {data
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row, index) => {
                      return (
                        <TableRow
                          hover
                          role="checkbox"
                          tabIndex={-1}
                          key={index}
                        >
                          {columns.map((column) => {
                            const value = row[column.id];
                            return (
                              <TableCell key={column.id} align={column.align}>
                                {column.id === "createdAt"
                                  ? moment
                                    .unix(value / 1000)
                                    .format("DD-MM-YYYY hh:mm a")
                                  : column.format && typeof value === "number"
                                    ? column.format(value)
                                    : value}
                              </TableCell>
                            );
                          })}
                        </TableRow>
                      );
                    })}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[10, 25, 100]}
              component="div"
              count={data && data.length ? data.length : 0}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Paper>
        </div>
      )}
    </div>
  );
}
