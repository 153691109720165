import { CopyAll } from "@mui/icons-material";
import React, { useState, useEffect, useRef } from "react";
import Sidebar from "../../components/Sidebar";

function Api() {
  const userInfo = localStorage.getItem("user")
    ? JSON.parse(localStorage.getItem("user"))
    : { uid: "" };

  const [copyClicked, setCopyClicked] = useState(false);
  const [copyUIDClicked, setCopyUIDClicked] = useState(false);
  let token = useRef();
  let uid = useRef();

  const handleUIDCopy = async (e) => {
    e.preventDefault();

    let copyUIDToken = uid.current.innerText;

    let textArea = document.createElement("textarea");
    textArea.value = copyUIDToken;
    // make the textarea out of viewport
    textArea.style.position = "fixed";
    textArea.style.left = "-999999px";
    textArea.style.top = "-999999px";
    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();
    return new Promise((res, rej) => {
      // here the magic happens
      document.execCommand("copy") ? res() : rej();
      textArea.remove();
      setCopyUIDClicked(true);
    });
  };

  const handleCopy = async (e) => {
    e.preventDefault();

    let copyToken = token.current.innerText;

    let textArea = document.createElement("textarea");
    textArea.value = copyToken;
    // make the textarea out of viewport
    textArea.style.position = "fixed";
    textArea.style.left = "-999999px";
    textArea.style.top = "-999999px";
    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();
    return new Promise((res, rej) => {
      // here the magic happens
      document.execCommand("copy") ? res() : rej();
      textArea.remove();
      setCopyClicked(true);
    });
  };

  useEffect(() => {
    setTimeout(() => {
      setCopyUIDClicked(false);
      setCopyClicked(false);
    }, 1000);
  }, [copyClicked, copyUIDClicked]);

  return (
    <div className="flex w-screen ">
      <Sidebar />
      <div className="body w-full flex-1  p-8">
        <h1 className="mb-4 font-mono text-3xl text-green-500">
          POST API DOCUMENT
        </h1>

        <div className="mb-8 w-10/12 rounded-xl bg-green-200 p-2 text-white">
          <p className="mb-2 text-xl font-semibold text-green-500">
            API SETTINGS
          </p>
          <hr />

          <div>
            <div className="flex items-center justify-between p-4">
              <div>
                <span className="mr-2 text-green-500">User ID:</span>
                <span className="break-all text-white">Crediential Key</span>
              </div>
              <div>
                <button
                  onClick={handleUIDCopy}
                  className="text-white"
                  disabled={copyUIDClicked}
                >
                  <CopyAll />
                  {copyUIDClicked ? (
                    <span className="text-green-600">Copied!!</span>
                  ) : (
                    "Copy Crediential"
                  )}
                </button>
              </div>
            </div>
            <p
              className=" mt-4 mb-4 h-16 overflow-auto break-all rounded-lg border-2 border-emerald-600 text-xs"
              ref={uid}
            >
              {userInfo.uid}
            </p>
          </div>
          <div className="mb-4 rounded-xl bg-white p-4">
            <h3 className="mb-4 border-y-2 border-blue-500 text-xl font-semibold text-green-600">
              GET METHOD
            </h3>
            <p className="font-semibold text-green-600">
              [SEND MESSAGE ONLY] :
              <span className=" ml-2 text-sm font-light text-blue-600">
                https://messageapi.in/chat/sendmessage?id=USERID&phone=91RECEIVERNO&message=MESSAGE
              </span>
            </p>
            <p className="font-semibold text-green-600">
              [SEND FILE ONLY] :
              <span className=" ml-2 text-sm font-light text-blue-600">
                https://messageapi.in/chat/sendfile?id=USERID&phone=91RECEIVERNO&file=URL
              </span>
            </p>
            <p className="font-semibold text-green-600">
              [SEND FILE WITH MESSAGE] :
              <span className=" ml-2 text-sm font-light text-blue-600">
                https://messageapi.in/chat/sendmessagefile?id=USERID&phone=91RECEIVERNO&message=MESSAGE&file=URL
              </span>
            </p>
          </div>
          <div className="mb-4 rounded-xl bg-white p-4">
            <h3 className="mb-4 border-y-2 border-blue-500 text-xl font-semibold text-green-600">
              POST METHOD
            </h3>
            <p className="font-semibold text-green-600">
              [SEND MESSAGE ONLY] :
              <span className=" ml-2 text-sm font-light text-blue-600">
                https://messageapi.in/chat/sendmessage
              </span>
              <span className="mx-2 text-sm text-green-600">Parameters</span>
              <span className=" ml-2 text-sm font-light text-blue-600">
                {JSON.stringify({
                  id: "USER ID",
                  phone: "91RECEIVERNO",
                  message: "hello world",
                })}
              </span>
            </p>
            <p className="font-semibold text-green-600">
              [SEND FILE ONLY] :
              <span className=" ml-2 text-sm font-light text-blue-600">
                https://messageapi.in/chat/sendfile/USERID
              </span>
              <span className="mx-2 text-sm text-green-600">Parameters</span>
              <span className=" ml-2 text-sm font-light text-blue-600">
                {JSON.stringify({
                  file: "",
                  phone: "91RECEIVERNO",
                })}
              </span>
            </p>
            <p className="font-semibold text-green-600">
              [SEND FILE WITH MESSAGE] :
              <span className=" ml-2 text-sm font-light text-blue-600">
                https://messageapi.in/chat/sendmessagefile/USERID
              </span>
              <span className="mx-2 text-sm text-green-600">Parameters</span>
              <span className=" ml-2 text-sm font-light text-blue-600">
                {JSON.stringify({
                  file: "",
                  phone: "91RECEIVERNO",
                  message: "hello world",
                })}
              </span>
            </p>
          </div>
          <div>
            <h3 className="font-semibold text-green-500">
              POSTMAN ALL API [GETTING STARTED GUIDE]:
            </h3>
            <hr className="mb-4" />
            <p className="text-md text-green-600">
              STEP I :- Copy this URL:
              <span className="ml-4 text-sm text-blue-600">
                https://www.getpostman.com/collections/10cf8940cbd970e07d16
              </span>
            </p>
            <p className="text-md text-green-600">
              STEP II :- Import into Postman API Application{" "}
            </p>
            <p></p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Api;
